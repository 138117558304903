import React from 'react';
import classnames from 'classnames';
import Button from 'components/UI/Button';
import Icon from 'components/UI/Icon';
import useGetCirrusoftModal from 'hooks/useGetCirrusoftModal';
import * as styles from './contentFooter.module.scss';

const ContentFooter = ({ data }) => {
  const { openGetCirrusModal } = useGetCirrusoftModal();

  return (
    <div className={classnames(styles.extra)}>
      <Button
        className={classnames(
          styles.link,
          'cr-btn--icon cr-btn--large cr-btn--wide cr-btn--icon-red-bg'
        )}
        onClick={openGetCirrusModal}
      >
        {data.button.text}

        <Icon name="corner-arrow-small-bold" />
      </Button>

      <ul className={styles.logos}>
        {data.logos.map((logoSrc, index) => (
          <li key={index}>
            <img src={logoSrc} alt="" className={classnames(styles.logo)} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ContentFooter;
