const leadershipPage = (WpTag) => `
  heroTitle
  heroTitleHighlighted
  heroSubtitle
  heroDescription
  leadersList {
    leaderName
    leaderDescription {
      descriptionText
      descriptionTitle
    }
    socialUrl {
      url
      title
    }
    leaderPosition
    leaderPhoto {
      sourceUrl
    }
  }
  connectTitle
  connectDescription
  connecrSocialUrl
`;

module.exports.leadershipPage = leadershipPage;
