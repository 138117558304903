import React, { useMemo } from 'react';
import classnames from 'classnames';

import { getImage, withArtDirection, GatsbyImage } from 'gatsby-plugin-image';

import QuestionsSwiper from './components/QuestionsSwiper';
import useIntersection from 'hooks/useIntersection';
import useStaticImage from 'hooks/graphql/useStaticImage';

import * as styles from './styles.module.scss';

const Questions = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.4,
  });
  const getStaticImage = useStaticImage();

  const images = useMemo(
    () =>
      withArtDirection(
        getImage(
          getStaticImage('gas-stations/questions/img-desktop.jpg')
            .childImageSharp
        ),
        [
          {
            media: '(min-width: 577px) and (max-width: 1200px)',
            image: getImage(
              getStaticImage('gas-stations/questions/img-tablet.jpg')
                .childImageSharp
            ),
          },
          {
            media: '(max-width: 576px)',
            image: getImage(
              getStaticImage('gas-stations/questions/img-mobile.jpg')
                .childImageSharp
            ),
          },
        ]
      ),
    [getStaticImage]
  );

  return (
    <div className={styles.questions} ref={scrollWrapper}>
      <div
        className={classnames('cr-wrap', styles.questions_inner, {
          [styles.jsAnimInit]: inViewSec,
        })}
        ref={inViewRefSec}
      >
        <span className={styles.questions_inner_bg_1}></span>
        <span className={styles.questions_inner_bg_2}></span>

        <div className={styles.questions_inner_head}>
          <div>
            <p
              className={classnames(
                'cr-subheader cr-main',
                styles.questions_txt_suptitle
              )}
            >
              {data.title}
            </p>

            <h2 className={classnames('cr-h2 cr-white')}>{data.subtitle}</h2>
          </div>
        </div>

        <div className={styles.questions_inner_img}>
          <div className={styles.questions_img}>
            <GatsbyImage image={images} alt={data.title} />
          </div>
        </div>

        <div className={styles.questions_inner_swiper}>
          <QuestionsSwiper slides={data.slides} popupData={data.popupBtn} />
        </div>
      </div>
    </div>
  );
};

export default Questions;
