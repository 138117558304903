const hospitalityPage = (WpTag) => `
  heroTitle
  heroTitleHighlighted
  heroSubtitle
  heroListItems {
    heroListText
  }
  solutionsTitle
  solutionsSubtitle
  solutionSwipeItems {
    solutionSwipeText
    solutionSwipeIcon {
      sourceUrl
    }
  }
  infograph1Title
  infograph1Subtitle
  infograph1TextItems {
    highlightedText
    nonhighlightedText
  }
  infograph1Button1 {
    buttonText
  }
  infograph1Button2 {
    buttonLink
    buttonText
  }
  infograph2Title
  infograph2Subtitle
  infograph2TextItems {
    highlightedText
    nonhighlightedText
  }
  infograph2Button1 {
    buttonText
  }
  infograph2Button2 {
    buttonLink
    buttonText
  }
  infograph3Title
  infograph3Subtitle
  infograph3TextItems {
    highlightedText
    nonhighlightedText
  }
  infograph3Button1 {
    buttonText
  }
  infograph3Button2 {
    buttonLink
    buttonText
  }
  transTitle
  transLead
  crossplatTitle
  crossplatLead
  benefitsTitle
  benefitsTableTh {
    cirrus
    traditional
  }
  benefitsTable {
    tdCirrus
    tdCirrusHighlighted
    tdIcon {
      sourceUrl
    }
    tdIconText
    tdTraditional
  }
  newsTitle
`;

module.exports.hospitalityPage = hospitalityPage;
