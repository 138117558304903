import React, { useState } from 'react';
import classnames from 'classnames';
import Lottie from 'lottie-web-react';
import MapPoints from 'components/MapPoints';

import { MOBILE_KEY } from 'constants/devices.js';
import useDeviceState from 'hooks/useDeviceState';
import useIntersection from 'hooks/useIntersection';

import * as styles from './research.module.scss';

const watchAnimationData = require('./mapPointsDesktopLottie.json');
const renderer = 'svg';
const rendererSettings = {
  preserveAspectRatio: 'xMinYMin slice',
};

const Research = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.4,
  });

  const deviceState = useDeviceState();

  const [playingState] = useState('play');
  const [loop] = useState(true);
  const [animationData] = useState(watchAnimationData);

  return (
    <section
      className={classnames(styles.section)}
      ref={scrollWrapper}
      id="Company"
    >
      <div className="cr-wrap">
        <div
          className={classnames(styles.inner, {
            [styles.jsAnimInit]: inViewSec,
          })}
          ref={inViewRefSec}
        >
          <div className={classnames(styles.content)}>
            <p
              className={classnames(
                styles.subtitle,
                'cr-subheader cr-subtitle-sec cr-text-3 '
              )}
            >
              {data.subtitle}
            </p>

            <h2
              className={classnames(
                styles.title,
                'cr-h2 cr-underline-gradient'
              )}
            >
              {data.title}
            </h2>

            <p className={classnames(styles.description, 'cr-lead')}>
              {data.description}
            </p>
          </div>

          <div className={classnames(styles.mediaWrap)}>
            <div className={classnames(styles.media)}>
              <i className={classnames(styles.animContainer)}>
                {deviceState !== MOBILE_KEY ? (
                  <Lottie
                    options={{
                      renderer: renderer,
                      loop: loop,
                      animationData: animationData,
                      rendererSettings: rendererSettings,
                    }}
                    playingState={playingState}
                  />
                ) : (
                  <MapPoints />
                )}
              </i>
            </div>

            <div className={classnames(styles.pointA)}>
              <div className={classnames(styles.pointTitle)}>
                <p className="cr-h5">{data.point_a.title}</p>
                <p className="cr-h5">{data.point_a.subtitle}</p>
              </div>

              <ul className={classnames(styles.pointList)}>
                {data.point_a.list.map((item) => (
                  <li key={item.item}>{item.item}</li>
                ))}
              </ul>
            </div>

            <div className={classnames(styles.pointB)}>
              <div className={classnames(styles.pointTitle)}>
                <p className="cr-h5">{data.point_b.title}</p>
                <p className="cr-h5">{data.point_b.subtitle}</p>
              </div>

              <ul className={classnames(styles.pointList)}>
                {data.point_b.list.map((item) => (
                  <li key={item.item}>{item.item}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Research;
