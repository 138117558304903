import React from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';
import Icon from 'components/UI/Icon';
import Tiles from 'components/Elements/Tiles';
import Swiper from './Swiper';

import Button from 'components/UI/Button';
import useDeviceState from 'hooks/useDeviceState';
import { MOBILE_KEY } from 'constants/devices.js';

import * as styles from './features.module.scss';
import useIntersection from 'hooks/useIntersection';
import useGetCirrusoftModal from 'hooks/useGetCirrusoftModal';

const FeaturesDesktop = ({ data }) => {
  const { openGetCirrusModal } = useGetCirrusoftModal();
  const deviceState = useDeviceState();

  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.3,
  });

  return (
    <section className={classnames(styles.section)} ref={scrollWrapper}>
      <div className={'cr-wrap'}>
        <div
          className={classnames(styles.inner, {
            [styles.jsAnimInit]: inViewSec,
          })}
        >
          <div className={classnames(styles.head)}>
            <p
              className={classnames(styles.subtitle, 'cr-subheader cr-text-3')}
            >
              {data.subtitle}
            </p>
            <h2
              className={classnames(styles.title, 'cr-h2')}
              ref={inViewRefSec}
            >
              {data.title}
            </h2>
          </div>

          <div className={classnames(styles.tiles)}>
            {deviceState === MOBILE_KEY ? (
              <Swiper data={data.list} />
            ) : (
              <Tiles data={data.list} />
            )}
          </div>

          <div className={classnames(styles.links)}>
            <Button
              className={classnames(
                'cr-btn--icon cr-btn--icon-arrow cr-btn--wide cr-btn--large cr-btn--icon-red-bg'
              )}
              onClick={openGetCirrusModal}
            >
              {data.links[0]?.txt}
              <Icon name="corner-arrow-small-bold" />
            </Button>

            <Link
              to={data.links[1]?.to?.uri || '#'}
              className={classnames(
                'cr-btn cr-btn--icon cr-btn--icon-arrow cr-btn--wide cr-btn--large'
              )}
            >
              {data.links[1]?.txt}
              <Icon name="corner-arrow-small-bold" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesDesktop;
