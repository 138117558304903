import React from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Button from 'components/UI/Button';
import Icon from 'components/UI/Icon';

import useGetCirrusoftModal from 'hooks/useGetCirrusoftModal';
import useIntersection from 'hooks/useIntersection';

const InfoGraphics = ({ data }) => {
  const { openGetCirrusModal } = useGetCirrusoftModal();
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.4,
  });

  return (
    <div ref={scrollWrapper}>
      <div
        className={classnames(styles.info, {
          [styles.info_reversed]: data.isReversed,
          [styles.jsAnimInit]: inViewSec,
        })}
        ref={inViewRefSec}
      >
        <div className={styles.info_media}>
          <GatsbyImage image={data.mediaSrc} alt={data.title} />
        </div>

        <div className={classnames(styles.info_txt)}>
          <div className={styles.txt_top}>
            <p
              className={classnames(
                'cr-subheader cr-text-3',
                styles.txt_top_suptitle
              )}
            >
              {data.suptitle}
            </p>
            <h2 className={classnames('cr-h2 cr-underline-gradient')}>
              {data.title}
            </h2>
          </div>

          <div className={styles.txt_main}>
            <ul className={classnames('cr-lead cr-checked-list')}>
              {data.list.map((item, i) => (
                <li key={i} dangerouslySetInnerHTML={{ __html: item }}></li>
              ))}
            </ul>
          </div>

          <div className={styles.txt_bot}>
            <Button
              className={classnames(
                'cr-btn cr-btn--icon cr-btn--large cr-btn--icon-red-bg'
              )}
              onClick={openGetCirrusModal}
            >
              {data.btn1?.text}
              <Icon name="corner-arrow-small-bold" />
            </Button>

            <Link
              to={data.btn2?.to}
              className={classnames('cr-btn cr-btn--icon cr-btn--large')}
            >
              {data.btn2?.text}
              <Icon name="corner-arrow-small-bold" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoGraphics;
