import React from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

import WpImage from 'components/Elements/WPImage';

import useIntersection from 'hooks/useIntersection';

const PartnerPerson = ({ person }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.4,
  });

  return (
    <figure
      className={classnames(styles.person, { [styles.jsAnimInit]: inViewSec })}
      ref={scrollWrapper}
    >
      <div className={styles.person_img_wrap} ref={inViewRefSec}>
        <WpImage
          src={person.avatar || '#'}
          alt={person.name}
          className={styles.person_img}
        />
      </div>

      <figcaption className={styles.person_text}>
        <p className={styles.person_text_name}>{person.name}</p>
        <p className={classnames('cr-lead cr-white')}>{person.position}</p>
      </figcaption>
    </figure>
  );
};

export default PartnerPerson;
