import React from 'react';
import classnames from 'classnames';
import Icon from 'components/UI/Icon';
import Statistics from 'sections/common/Statistics';
import useIntersection from 'hooks/useIntersection';
import * as styles from './glance.module.scss';

const Glance = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.6,
  });

  return (
    <section className={classnames(styles.section)} ref={scrollWrapper}>
      <div className={classnames('cr-wrap')}>
        <div
          className={classnames(styles.header, inViewSec && styles.jsAnimInit)}
          ref={inViewRefSec}
        >
          <p
            className={classnames(
              'cr-subheader cr-subtitle-sec cr-text-3 cr-underline-gradient'
            )}
          >
            {data.subtitle && data.subtitle}
          </p>

          <div className={classnames(styles.header_inner)}>
            <h2 className={classnames(styles.title, 'cr-h2')}>
              {data.title && data.title}
              {/* TODO: присутствует маркировка буквы 'i', нужно это настроить с вордпреса ? */}
              {/* CiRRUS is a part of{' '}
              <span className="cr-underline-bold-line cr-underline-bold-line--main">
                B<span className="cr-main">i</span>CA
              </span>{' '}
              — a reliable &amp; trusted partner for modern technology solutions */}
            </h2>

            <i className={classnames(styles.logo)}>
              <Icon name="bica-logo" />
            </i>
          </div>
        </div>

        <Statistics list={data.statistics} />
      </div>
    </section>
  );
};

export default Glance;
