import React from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';

import WpImage from 'components/Elements/WPImage';

import Formating from 'assets/js/formating';
import useLocale from 'hooks/useLocale';

import * as styles from './styles.module.scss';

const NewsCard = ({ news }) => {
  const locale = useLocale();

  return (
    <Link to={news.uri} className={styles.news}>
      <div className={styles.news_image}>
        <WpImage
          src={news.blog.banner?.sourceUrl}
          alt={news.title}
          className={styles.news_image_inner}
        />
      </div>
      <div className={classnames(styles.news_text)}>
        <div className={styles.newstext_top}>
          <p
            className={classnames(
              'cr-sub cr-main cr-txt-up',
              styles.newstext_suptitle
            )}
          >
            {news.categories.nodes.map(({ name }) => name).join(' ')}
          </p>

          <p className={classnames('cr-sub cr-text-2', styles.newstext_date)}>
            {Formating.date(news.dateGmt, locale.code)}
          </p>
        </div>

        <p className={classnames('cr-h3 cr-text-1', styles.newstext_title)}>
          {news.title}
        </p>
      </div>
    </Link>
  );
};

export default NewsCard;
