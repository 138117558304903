import React, { useMemo } from 'react';
import classnames from 'classnames';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Icon from 'components/UI/Icon';
import useIntersection from 'hooks/useIntersection';
import useStaticImage from 'hooks/graphql/useStaticImage';

import * as styles from './cloud.module.scss';

const Cloud = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.3,
  });

  const getStaticImage = useStaticImage();

  const image = useMemo(
    () => getImage(getStaticImage('Home/Cloud/home-cloud.png').childImageSharp),
    [getStaticImage]
  );

  return (
    <section className={classnames(styles.section)} ref={scrollWrapper}>
      <div className="cr-wrap">
        <div
          className={classnames(styles.inner, inViewSec && styles.jsAnimInit)}
        >
          <div className={classnames(styles.flexContainer)}>
            <div className={classnames(styles.content)}>
              <p
                className={classnames(
                  styles.subtitle,
                  'cr-text-3 cr-subheader cr-opacity-05'
                )}
              >
                {data.subtitle}
              </p>
              <h2
                className={classnames(styles.title, 'cr-white cr-h2')}
                ref={inViewRefSec}
              >
                {data.title}
              </h2>
              <p
                className={classnames(
                  styles.description,
                  'cr-reg cr-white cr-opacity-05'
                )}
              >
                {data.description}
              </p>
              <AnchorLink
                to={data.btnUrl.uri + '#Cloud'}
                className={classnames(
                  styles.link,
                  'cr-btn cr-btn--icon cr-btn--icon-arrow cr-btn--wide cr-btn--large cr-btn--icon-red-txt cr-btn--icon-red-txt_white-bg'
                )}
              >
                {data.btnText}
                <Icon name="corner-arrow-small-bold" />
              </AnchorLink>
            </div>

            <div className={classnames(styles.graphics)}>
              <GatsbyImage image={image} alt={data.title} />
            </div>
          </div>

          <ul className={classnames(styles.logos)}>
            {data.logos?.map((logo, i) => (
              <li key={i}>
                <img src={logo.cloudLogoImage?.sourceUrl} alt="" />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Cloud;
