import React, { useRef, useEffect } from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Pagination } from 'swiper';

import Card from '../Card';
import SwiperPagin from 'components/UI/SwiperPagin';

import useIntersection from 'hooks/useIntersection';

SwiperCore.use([Pagination]);

const MobileList = React.memo(({ list }) => {
  const containerRef = useRef(null);
  const paginRef = useRef(null);

  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.6,
  });

  useEffect(() => {
    const swiper = new Swiper(containerRef.current, {
      slidesPerView: 'auto',
      pagination: {
        el: paginRef.current,
      },
    });

    return () => {
      swiper.destroy(true, true);
    };
  }, []);

  return (
    <div
      className={classnames(styles.swiper, inViewSec && styles.jsAnimInit)}
      ref={scrollWrapper}
    >
      <div className={classnames('swiper-container')} ref={containerRef}>
        <div className="swiper-wrapper" ref={inViewRefSec}>
          {list.map((card, i) => (
            <div key={i} className={classnames('swiper-slide', styles.slide)}>
              <Card card={card} />
            </div>
          ))}
        </div>
      </div>

      <div className={styles.swiper_cntrls}>
        <SwiperPagin ref={paginRef} />
      </div>
    </div>
  );
});

export default MobileList;
