import React from 'react';
import classnames from 'classnames';
import useIntersection from 'hooks/useIntersection';
import * as styles from './styles.module.scss';

const TransactionInfoCard = ({ data, className }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.9,
  });

  return (
    <div className={classnames(styles.card, className)} ref={scrollWrapper}>
      <hr className={styles.card_hr} />
      <p
        className={classnames(
          'cr-h3 cr-gradient',
          styles.card_title,
          inViewSec && styles.jsAnimInit
        )}
        ref={inViewRefSec}
      >
        {data.title}
      </p>

      <p className={classnames('cr-lead')}>{data.desc}</p>
    </div>
  );
};

export default TransactionInfoCard;
