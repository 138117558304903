import React from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

import Search from './components/Search';

const Header = ({ data, onFilter, search }) => (
  <div>
    <div className={styles.header_top}>
      <span
        className={classnames(
          'cr-text-3 cr-subheader',
          styles.header_top_title
        )}
      >
        {data.subtitle}
      </span>

      <Search
        value={search.title}
        onChange={(value) => onFilter({ title: value })}
      />
    </div>

    <h1 className={classnames('cr-h1', styles.header_title)}>{data.title}</h1>
  </div>
);

export default Header;
