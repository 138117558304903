import React, { useState, useMemo, useCallback } from 'react';
import { Formik } from 'formik';
import classnames from 'classnames';
import DropDefault from './components/DropDefault';

import Icon from 'components/UI/Icon';
import Button from 'components/UI/Button';

import Hero from './components/Hero';
import RadioBtns from './components/RadioBtns';
import Success from './components/Success';
import DropResult from './components/DropResult';
import Spinner from 'components/UI/Spinner';

import useLocale from 'hooks/useLocale';
import useGravityForm from 'hooks/useGravityForm';

import validationSchema from './validationSchema';

import * as styles from './style.module.scss';

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

function niceBytes(x) {
  let l = 0,
    n = parseInt(x, 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
}

const JoinTeam = ({ data }) => {
  const submitGravityForm = useGravityForm(4);
  const [uploadedFile, setUploadedFile] = useState('');
  const [isValidForm, setValidForm] = useState(false);
  const [isFormSubmiting, setFormSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const locale = useLocale();

  const handleUploadFile = useCallback((file) => {
    setUploadedFile(file);
  }, []);

  const clearUploadedFile = useCallback(() => {
    setUploadedFile('');
  }, []);

  const fileInfo = useMemo(
    () =>
      uploadedFile
        ? {
            name: uploadedFile.name,
            size: niceBytes(uploadedFile.size),
          }
        : null,
    [uploadedFile]
  );

  const onChangeHandler = useCallback((values, validateForm) => {
    setTimeout(async () => {
      const errors = await validateForm();
      const isValid = Object.keys(errors).length === 0;

      setValidForm(isValid);
    }, 100);
  }, []);

  return (
    <div className={styles.wrap}>
      {!isFormSubmiting ? (
        <>
          <Hero data={data} />
          <Formik
            initialValues={{
              area: data.area,
              post: '',
              file_cv: '',
            }}
            validationSchema={validationSchema(locale.slug)}
            onSubmit={(values, { setSubmitting }) => {
              setIsLoading(true);

              submitGravityForm(values)
                .then(() => {
                  setFormSubmitting(true);
                })
                .catch((err) => {
                  console.error(err.response);
                })
                .finally(() => {
                  setIsLoading(false);
                });

              setSubmitting(false);
            }}
            render={({ values, handleSubmit, setFieldValue, validateForm }) => (
              <form
                onSubmit={handleSubmit}
                onChange={() => {
                  onChangeHandler(values, validateForm);
                }}
              >
                <RadioBtns
                  data={data.vacancies}
                  onChange={(value) => {
                    setFieldValue('post', value);
                  }}
                />

                {fileInfo ? (
                  <div className={classnames(styles.dropResultWrap)}>
                    <div className={classnames(styles.dropResultWrap_file)}>
                      <DropResult data={fileInfo} onClick={clearUploadedFile} />
                    </div>
                    <div>
                      <Button
                        type="submit"
                        className="cr-btn cr-btn--icon cr-btn--icon-arrow cr-btn--large cr-btn--icon-red-bg cr-btn--wide"
                        disabled={!isValidForm}
                      >
                        <span>Apply for this position</span>

                        <Icon name="corner-arrow-small-bold" />
                      </Button>
                    </div>
                  </div>
                ) : (
                  <DropDefault
                    onUpload={(file) => {
                      handleUploadFile(file);

                      setFieldValue('file_cv', file);
                    }}
                    name="file_cv"
                  />
                )}
              </form>
            )}
          />
        </>
      ) : (
        <Success />
      )}

      {isLoading && <Spinner size="80px" absolute />}
    </div>
  );
};

export default JoinTeam;
