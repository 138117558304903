const careersPage = (WpTag) => `
  fieldGroupName
  heroDescription
  heroSubtitle
  heroTitle
  join {
    title
    subtitle
    options {
      area
      description
      vacancies {
        position
      }
    }
  }
  reasonsSubtitle
  reasonsTitle
  reasonsTilesItems {
    fieldGroupName
    reasonTileItemDescription
    reasonTileItemImage {
      sourceUrl
      srcSet
      title
    }
    reasonTileItemTitle
  }
  banner {
    description
    image {
      altText
      srcSet
      sourceUrl
      mediaItemUrl
    }
  }
  blog {
    subtitle
    title
  }
`;

module.exports.careersPage = careersPage;
