import React from 'react';
import classnames from 'classnames';
import Icon from 'components/UI/Icon';
import * as styles from './dropResult.module.scss';

const DropResult = ({ data, onClick }) => {
  return (
    <div className={classnames(styles.result)}>
      <i className={classnames(styles.icon)}>
        <Icon name="drop-file" />
      </i>

      <p className={classnames(styles.name, 'cr-h4')}>{data.name}</p>

      <p className={classnames(styles.size, 'cr-reg cr-text-2 cr-opacity-05')}>
        {data.size}
      </p>

      <button
        type="button"
        className={classnames(styles.btn)}
        onClick={onClick}
      >
        <Icon name="close" />
      </button>
    </div>
  );
};

export default DropResult;
