import React, { useState, useCallback } from 'react';
import * as styles from './styles.module.scss';

import PartnerPerson from './components/PartnerPerson';
import PartnerLinks from './components/PartnerLinks';
import PartnerInfo from './components/PartnerInfo';

const Partners = ({ data }) => {
  const [curPartner, setCurPartner] = useState(data.partnersList[0]);

  const partnerClickHandler = useCallback((partner) => {
    setCurPartner(partner);
  }, []);

  if (!curPartner) return null;

  return (
    <div className={styles.partners}>
      <div className={styles.partners_person}>
        <PartnerPerson person={curPartner.person} />
      </div>

      <div className={styles.partners_links}>
        <PartnerLinks
          partners={data.partnersList}
          curPartner={curPartner}
          onPartnerClick={partnerClickHandler}
        />
      </div>

      <div className={styles.partners_info}>
        <PartnerInfo
          subHeader={data.partnersSubheader}
          btns={data.partnersBtns}
          comment={curPartner.comment}
          benefits={curPartner.benefits}
        />
      </div>
    </div>
  );
};

export default Partners;
