import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import useClickOutside from 'hooks/useClickOutside';

function ClickOutsideWrapper({ onClickOutside, ...props }) {
  const wrapperRef = useRef(null);

  useClickOutside(wrapperRef, () => {
    onClickOutside();
  });

  return <div ref={wrapperRef}>{props.children}</div>;
}

ClickOutsideWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ClickOutsideWrapper;
