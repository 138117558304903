// extracted by mini-css-extract-plugin
export var section = "cloud-module--section--h8ATg";
export var inner = "cloud-module--inner--3L6Ud";
export var jsAnimInit = "cloud-module--jsAnimInit--3Jjim";
export var content = "cloud-module--content--1msoA";
export var graphics = "cloud-module--graphics--2OSmN";
export var flexContainer = "cloud-module--flexContainer--PQsgn";
export var subtitle = "cloud-module--subtitle--2SU0v";
export var title = "cloud-module--title--12l9u";
export var description = "cloud-module--description--1zkjM";
export var link = "cloud-module--link--1stYW";
export var logos = "cloud-module--logos--LwnH3";