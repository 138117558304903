import React, { useRef, useEffect } from 'react';
import classnames from 'classnames';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import useIntersection from 'hooks/useIntersection';

import * as styles from './accordeon.module.scss';

const Accordeon = ({ accordeon }) => {
  const videoRef = useRef([]);
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.6,
  });

  useEffect(() => {
    inViewSec && videoRef.current[0].play();
  }, [inViewSec]);

  return (
    <div ref={scrollWrapper}>
      <div ref={inViewRefSec}>
        <Accordion
          onChange={(index) => videoRef.current[Number(index[0])].play()}
          className={classnames(styles.accordion)}
          aria-level="4"
          preExpanded="0"
        >
          {accordeon.length > 0 &&
            accordeon.map((item, index) => {
              return (
                <AccordionItem
                  uuid={index.toString()}
                  key={item.accordeonTitle}
                  className={classnames(styles.accordionItem)}
                >
                  <AccordionItemHeading
                    className={classnames(styles.accordionHeading)}
                  >
                    <AccordionItemButton
                      className={classnames(styles.accordionButton)}
                    >
                      <h3
                        className={classnames(
                          styles.accordionButton_title,
                          'cr-h3'
                        )}
                      >
                        {item.accordeonTitle}
                      </h3>
                    </AccordionItemButton>
                  </AccordionItemHeading>

                  <AccordionItemPanel
                    className={classnames(styles.accordionPanel)}
                  >
                    <div className={classnames(styles.panelText)}>
                      <p
                        className={classnames(
                          styles.panelText_description,
                          'cr-lead'
                        )}
                      >
                        {item.accordeonDescription}
                      </p>
                    </div>
                    <div className={classnames(styles.mediaWrap)}>
                      <video
                        src={item.videoSrc}
                        ref={(e) => (videoRef.current[index] = e)}
                        playsInline
                        muted
                        poster={item.poster}
                      ></video>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              );
            })}
        </Accordion>
      </div>
    </div>
  );
};

export default Accordeon;
