const homePage = (WpTag) => `
  heroTitle
  heroTitleHighlighted
  heroDescription
  heroTilesItems {
    heroTileItemTitle
    heroTileItemDescription
    heroTileItemLink {
      ... on ${WpTag}Page {
        id
        uri
      }
    }
    heroTileItemImage {
      sourceUrl
    }
  }
  solutionTitle
  solutionSubtitle
  solutionAccordeonItems {
    accordeonDescription
    accordeonTitle
  }
  cloudTitle
  cloudSubtitle
  cloudDescription
  cloudLink {
    ... on ${WpTag}Page {
      id
      uri
    }
  }
  cloudLinkText
  cloudLogos {
    cloudLogoImage {
      sourceUrl
    }
  }
  featuresTitle
  featuresSubtitle
  featuresLinks {
    featuresLinkSrc {
      ... on ${WpTag}Page {
        id
        uri
        link
      }
    }
    featuresLinkText
    fieldGroupName
  }
  featuresListItems {
    featuresListDescription
    featuresListLink {
      ... on ${WpTag}Page {
        id
        uri
        link
      }
    }
    featuresListTitle
    featuresListIcon {
      sourceUrl
    }
  }
  testimonialsTitle
  testimonialsSubtitle
  testimonialsLogos {
    testimonialsLogoImg {
      sourceUrl
    }
  }
  testimonialsSlideItems {
    slideItemText
    slideItemInfoInfo
    slideItemInfoAuthor
  }
  blogTitle
  blogSubtitle
  partners {
    logo {
      altText
      srcSet
      sourceUrl
      mediaItemUrl
    }
  }
`;

module.exports.homePage = homePage;
