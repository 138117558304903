import React, { useMemo } from 'react';
import * as styles from './styles.module.scss';
import CategoryLinksList from 'components/UI/CategoryLinksList';

const Aside = ({ data, onFilter, search }) => {
  const tags = useMemo(() => {
    return data.tags.map((tag) => ({ ...tag, name: `# ${tag.name}` }));
  }, [data.tags]);

  return (
    <div className={styles.aside}>
      <div className={styles.aside_inner}>
        <div className={styles.aside_links}>
          <CategoryLinksList
            title={data.categoriesTitle}
            list={data.categories}
            activeLink={search.category}
            onClick={(slug) => {
              onFilter({ category: search.category === slug ? '' : slug });
            }}
          />
        </div>
        <div className={styles.aside_links}>
          <CategoryLinksList
            title={data.tagsTitle}
            type="red"
            list={tags}
            activeLink={search.tag}
            onClick={(slug) => {
              onFilter({ tag: search.tag === slug ? '' : slug });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Aside;
