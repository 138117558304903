import React from 'react';

import * as styles from './Map.module.scss';
import GoogleMapComponent from './GoogleMapComponent';
import GoogleConstants from 'constants/google';

const Map = ({ data, ...props }) => {
  return (
    <div className={styles.wrap}>
      <GoogleMapComponent
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${GoogleConstants.map_key}`}
        containerElement={<div style={{ height: `100%`, width: '100%' }} />}
        loadingElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        center={{
          lat: 59.95,
          lng: 30.33,
        }}
        data={data}
        markers={[]}
      ></GoogleMapComponent>
    </div>
  );
};

export default Map;
