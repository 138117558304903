import React from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

import { DESKTOP_KEY } from 'constants/devices.js';
import useDeviceState from 'hooks/useDeviceState';
import DesktopList from './components/DesktopList';
import MobileList from './components/MobileList';

import useIntersection from 'hooks/useIntersection';

const Solutions = ({ data }) => {
  const deviceState = useDeviceState();
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.6,
  });

  return (
    <div
      ref={scrollWrapper}
      className={classnames(styles.solutions, inViewSec && styles.jsAnimInit)}
    >
      <p
        className={classnames(
          'cr-subheader cr-text-3',
          styles.solutions_suptitle
        )}
      >
        {data.subtitle}
      </p>
      <h2
        className={classnames('cr-h2', styles.solutions_title)}
        ref={inViewRefSec}
      >
        {data.title}
      </h2>

      <div>
        {deviceState === DESKTOP_KEY ? (
          <DesktopList list={data.list} />
        ) : (
          <MobileList list={data.list} />
        )}
      </div>
    </div>
  );
};

export default Solutions;
