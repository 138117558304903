import React, { useRef } from 'react';
import classnames from 'classnames';

import { GatsbyImage } from 'gatsby-plugin-image';

import ScaleOnScroll from '@/helpers/scaleOnScroll';
import TransactionInfoCard from './components/TransactionInfoCard';

import * as styles from './styles.module.scss';

const Transactions = ({
  data,
  bgClassName = '',
  images,
  cardClassName = '',
}) => {
  const scrollWrap = useRef();

  return (
    <div className={classnames('cr-wrap', styles.trans_wrap)}>
      <div className={styles.trans}>
        <ScaleOnScroll scrollWrapper={scrollWrap}>
          {(scale) => (
            <div
              className={classnames(styles.trans_bg, bgClassName)}
              ref={scrollWrap}
              style={{
                willChange: 'transform',
                transform: `scale3d(${scale.x}, ${scale.y}, ${scale.z})`,
              }}
            >
              <GatsbyImage image={images} alt={data.card.title} />
            </div>
          )}
        </ScaleOnScroll>

        <TransactionInfoCard
          className={classnames(styles.trans_card, cardClassName)}
          data={data.card}
        />
      </div>
    </div>
  );
};

export default Transactions;
