import React from 'react';
import classnames from 'classnames';
import Pos from 'assets/svg/icons/pos.svg';
import * as styles from './itemList.module.scss';

const ItemList = ({ data }) => (
  <div className={classnames(styles.item)}>
    <i className={classnames(styles.item_iconWrap)}>
      {data.iconSrc ? (
        <img src={data.iconSrc} alt="icon" />
      ) : (
        <img src={Pos} alt="icon" />
      )}
    </i>
    <div>
      <h4 className={classnames(styles.item_title, 'cr-h4')}>{data.title}</h4>
      <p className={classnames('cr-subheader-3')}>{data.text}</p>
    </div>
  </div>
);

export default ItemList;
