// extracted by mini-css-extract-plugin
export var ecosystem = "styles-module--ecosystem--1vIwN";
export var ecosystem_bg = "styles-module--ecosystem_bg--BMjN1";
export var ecosystem_inner = "styles-module--ecosystem_inner--1idAC";
export var ecosystem_inner_l = "styles-module--ecosystem_inner_l--3UhP-";
export var ecosystem_inner_r = "styles-module--ecosystem_inner_r--2AFam";
export var ecosystem_inner_r_btn = "styles-module--ecosystem_inner_r_btn--1g_NS";
export var ecosystem_txt = "styles-module--ecosystem_txt--x63lQ";
export var ecosystem_txt_title = "styles-module--ecosystem_txt_title--BMv2Q";
export var ecosystem_txt_desc = "styles-module--ecosystem_txt_desc--3erdI";
export var ecosystem_txt_btn = "styles-module--ecosystem_txt_btn--VBmeC";
export var ecosystem_graphics = "styles-module--ecosystem_graphics--AKNnB";
export var ecosystem_graphics_station = "styles-module--ecosystem_graphics_station---0lHc";
export var ecosystem_graphics_car = "styles-module--ecosystem_graphics_car--LAXYI";
export var ecosystem_graphics_card = "styles-module--ecosystem_graphics_card--23Ne_";
export var ecosystem_graphics_card_integration = "styles-module--ecosystem_graphics_card_integration--18nOG";
export var ecosystem_graphics_card_hardware = "styles-module--ecosystem_graphics_card_hardware--33kBX";
export var ecosystem_graphics_card_partnership = "styles-module--ecosystem_graphics_card_partnership--2hwZf";
export var ecosystem_graphics_card_privacy = "styles-module--ecosystem_graphics_card_privacy--1kAhw";
export var ecosystem_graphics_card_protection = "styles-module--ecosystem_graphics_card_protection--3vfaw";
export var ecosystem_graphics_card_standart = "styles-module--ecosystem_graphics_card_standart--2kAaL";
export var ecosystem_graphics_card_reports = "styles-module--ecosystem_graphics_card_reports--_lOc_";
export var ecosystem_graphics_card_setups = "styles-module--ecosystem_graphics_card_setups--3IA20";
export var ecosystem_graphics_card_modularity = "styles-module--ecosystem_graphics_card_modularity--3p6hj";
export var ecosystem_graphics_card_cloudbased = "styles-module--ecosystem_graphics_card_cloudbased--3Ndft";
export var ecosystem_graphics_card_pricing = "styles-module--ecosystem_graphics_card_pricing--k1DcU";
export var jsAnimInit = "styles-module--jsAnimInit--lQdeN";
export var braking = "styles-module--braking--1SZKF";
export var wheelDepreciation = "styles-module--wheelDepreciation--MHlzP";