// extracted by mini-css-extract-plugin
export var wrap = "Contact-module--wrap--atkkY";
export var top = "Contact-module--top--EbXqC";
export var addresses = "Contact-module--addresses--2WqAb";
export var bottom = "Contact-module--bottom--2Om3o";
export var bottom_left = "Contact-module--bottom_left--2w1iJ";
export var bottom_right = "Contact-module--bottom_right--x-e1l";
export var help = "Contact-module--help--336mt";
export var contacts = "Contact-module--contacts--2Kf_J";
export var contacts_email = "Contact-module--contacts_email--SZ-r-";
export var contacts_phone = "Contact-module--contacts_phone--17cER";