import React from 'react';
import classnames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import TileItem from 'components/Elements/Tiles/Item';

import './styles.scss';

SwiperCore.use(Pagination);

const SwiperContainer = ({ data }) => {
  return (
    <div className="cr-features-mob-swiper cr-swiper-controls-pagination ">
      <Swiper
        pagination={{
          clickable: true,
        }}
        slidesPerView={1}
        slidesPerColumn={2}
      >
        {data.length &&
          data.map((slide, index) => (
            <SwiperSlide
              key={index}
              className={classnames('cr-features-mob-swiper_item')}
            >
              <TileItem data={slide} />
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};

export default SwiperContainer;
