const featuresPage = (WpTag) => `
  heroTitle
  heroTitleHighlighted
  heroSubheader
  heroDescription
  mediaModuleTextItems {
    slogan
  }
  itemsectionTitle
  itemsectionDescription
  itemsectionNamePosition
  itemsectionButton {
    itemsectionButtonText
  }
  itemsectionTestimonialsQuote
  itemsectionTestimonialsPicture {
    sourceUrl
  }
  itemsectionListItems {
    itemDescription
    itemTitle
    itemIcon {
      sourceUrl
    }
  }
  itemsectionTestimonialsName
  itemsectionTestimonialsLogo {
    sourceUrl
  }
  itemsection2Title
  itemsection2Description
  itemsection2Tiles {
    tile2Description
    tile2Title
    tile2Icon {
      sourceUrl
    }
  }
  itemsection2TestimonialsPicture {
    sourceUrl
  }
  itemsection2NamePosition
  itemsection2TestimonialsName
  itemsection2TestimonialsQuote
  itemsection2TestimonialsLogo {
    sourceUrl
  }
  itemsection2Button {
    itemsection2ButtonText
  }
  itemsection3Title
  itemsection3Description
  itemsection3Tiles {
    tile3Description
    tile3Title
    tile3Icon {
      sourceUrl
    }
  }
  itemsection3NamePosition
  itemsection3TestimonialsLogo {
    sourceUrl
  }
  itemsection3TestimonialsName
  itemsection3TestimonialsPicture {
    sourceUrl
  }
  itemsection3TestimonialsQuote
  itemsection3Button {
    itemsection3ButtonText
  }
  cloudSectionTitle
  cloudSectionTitleHighlighted
  cloudAdvantageslist {
    listDescription
    listTitle
  }
  cloudBenefitsList {
    benefitslistTitle
    benefitslistText
  }
  cloudButton {
    cloudButtonText
  }
  cloudLogo {
    logoItem {
      sourceUrl
    }
  }
  rundeviceTitle
  rundeviceDescription
  casesTitle
  casesLogos {
    logoItem {
      sourceUrl
    }
  }
  casesCards {
    cardTitle
    cardLink
    cardDescription
    cardImage {
      sourceUrl
    }
  }
  casesButton {
    casesButtonText
    casesButtonLink
  }
  partners {
    logo {
      altText
      srcSet
      sourceUrl
      mediaItemUrl
    }
  }
`;

module.exports.featuresPage = featuresPage;
