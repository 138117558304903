import React, { useMemo } from 'react';
import classnames from 'classnames';

import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Icon from 'components/UI/Icon';

import useReusableBlocks from 'hooks/graphql/useReusableBlocks';
import useStaticImage from 'hooks/graphql/useStaticImage';

import * as styles from './styles.module.scss';

const BecomeFriend = ({ data }) => {
  const { forms_ui: formData } = useReusableBlocks();
  const getStaticImage = useStaticImage();

  const image = useMemo(
    () =>
      getImage(getStaticImage('leadership/team/connect.png').childImageSharp),
    [getStaticImage]
  );

  return (
    <div className={styles.block}>
      <div className={styles.block_txt}>
        <h2 className={classnames('cr-h2', styles.block_txt_title)}>
          {data.title}
        </h2>
        <p className={classnames('cr-lead', styles.block_txt_desc)}>
          {data.description}
        </p>

        <Link
          to={data.socLinkUrl || '#'}
          target="_blank"
          rel="noreferrer"
          className="cr-btn cr-btn--white cr-btn--connect cr-btn--connect-blue"
        >
          <Icon name="linkedIn" />
          <span>LinkedIn {formData.buttons.connect}</span>
          <i></i>
        </Link>
      </div>
      <div className={styles.block_img}>
        <GatsbyImage
          image={image}
          alt={data.title}
          className={styles.block_img_elem}
        />
      </div>
    </div>
  );
};

export default BecomeFriend;
