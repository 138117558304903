import React, { useRef } from 'react';
import classnames from 'classnames';

import ScaleOnScroll from '@/helpers/scaleOnScroll';
import * as styles from './testimonial.module.scss';

const Testimonial = ({ data }) => {
  const scrollWrap = useRef();

  return (
    <ScaleOnScroll scrollWrapper={scrollWrap} percentIteration="10">
      {(scale) => (
        <div
          className={classnames(styles.testimonial)}
          ref={scrollWrap}
          style={{
            willChange: 'transform',
            transform: `translateY(calc(${scale.y}em * -1))`,
          }}
        >
          <div className={classnames(styles.inner)}>
            <picture className={classnames(styles.testimonial_imgAuthorWrap)}>
              {data.avatar && <img src={data.avatar} alt={data.name} />}
            </picture>

            <blockquote className={classnames(styles.testimonial_blockquote)}>
              <p className={classnames('cr-lead')}>{data.bloquote}</p>
            </blockquote>

            <div className={classnames(styles.testimonial_info)}>
              <div>
                <h5 className={classnames('cr-h5 cr-main')}>{data.name}</h5>
                <p className={classnames('cr-reg cr-text-1 cr-opacity-05')}>
                  {data.position}
                </p>
              </div>

              <picture className={classnames(styles.logoWrap)}>
                {data.logoSrc && <img src={data.logoSrc} alt="" />}
              </picture>
            </div>
          </div>
        </div>
      )}
    </ScaleOnScroll>
  );
};

export default Testimonial;
