import React from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

import { pad } from 'assets/js/lib';

const QuestionsSlide = ({ slide, index }) => (
  <div className={styles.slide}>
    <div className={styles.slide_top}>
      <span
        className={classnames('cr-text-3 cr-subheader', styles.slide_number)}
      >
        {pad(index + 1)}
      </span>
    </div>

    <div className={styles.slide_main}>
      <div className={classnames('cr-white cr-h3', styles.slide_main_question)}>
        {slide.question}
      </div>

      <div className={classnames('cr-lead', styles.slide_main_answer)}>
        {slide.answer}
      </div>
    </div>
  </div>
);

export default QuestionsSlide;
