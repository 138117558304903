import React from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';
import Icon from 'components/UI/Icon';
import * as styles from './item.module.scss';

const Item = ({ data }) => {
  return (
    // TODO: переделать компонент под данные с бэкенда
    <>
      {data.url ? (
        // TODO: If Item has url then Link
        <Link
          to={data.url}
          className={classnames(styles.item, styles.item_link)}
        >
          <p className={classnames(styles.iconsWrap)}>
            <i>
              {data.icon ? (
                <img src={data.icon} alt="icon" />
              ) : (
                <Icon name="order" />
              )}
            </i>

            <i className={classnames(styles.iconsWrap_arrow)}>
              <Icon name={'corner-arrow-small-bold'} />
            </i>
          </p>

          <h3 className={classnames(styles.title, 'cr-h4')}>{data.title}</h3>

          <p className={classnames(styles.description, 'cr-reg')}>
            {data.description}
          </p>
        </Link>
      ) : (
        // TODO: If Item has't url then div
        <div className={classnames(styles.item)}>
          <p className={classnames(styles.iconsWrap)}>
            <i>
              {data.icon ? (
                <img src={data.icon} alt="icon" />
              ) : (
                <Icon name="order" />
              )}
            </i>
          </p>

          <h3 className={classnames(styles.title, 'cr-h3')}>{data.title}</h3>

          <p className={classnames(styles.description)}>{data.description}</p>
        </div>
      )}
    </>
  );
};

export default Item;
