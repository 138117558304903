import React, { useState } from 'react';

import FormElem from './components/FormElem';
import Finish from './components/Finish';

import useGravityForm from 'hooks/useGravityForm';
import Spinner from 'components/UI/Spinner';

const FormContact = ({ data }) => {
  const [isFinish, setIsFinish] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const submitGravityForm = useGravityForm(5);

  const handleSubmit = (data) => {
    setShowSpinner(true);

    submitGravityForm(data)
      .then(() => {
        setShowSpinner(false);
        setIsFinish(true);
      })
      .catch((err) => {
        console.error(err.response);
        setShowSpinner(false);
        setIsFinish(true);
      });
  };

  return (
    <div>
      {!isFinish ? (
        <>
          <div>
            <p className="cr-lead cr-txt-medium">{data.texts.title}</p>
            <p className="cr-lead cr-text-2">{data.texts.subtitle}</p>
          </div>

          <FormElem
            placeholders={data.placeholders}
            options={data.options}
            onSubmit={handleSubmit}
          />

          {showSpinner && <Spinner />}
        </>
      ) : (
        <Finish data={data.finish} />
      )}
    </div>
  );
};

export default FormContact;
