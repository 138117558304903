import React from 'react';
import classnames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import useIntersection from 'hooks/useIntersection';
import './timelineSwiper.scss';
import './controls.scss';

SwiperCore.use(Navigation, Pagination);

const TimelineSwiper = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.8,
  });

  return (
    <div ref={scrollWrapper}>
      <div
        className={classnames(
          'cr-swiper-controls-navigation',
          inViewSec && 'cr-js-anim-init',
          'cr-timeline-swiper'
        )}
        ref={inViewRefSec}
      >
        <Swiper
          navigation
          pagination
          slidesPerView={3}
          breakpoints={{
            1200: {
              slidesPerView: 3,
            },
            992: {
              slidesPerView: 2.5,
            },
            576: {
              slidesPerView: 2,
            },
            320: {
              slidesPerView: 1.1,
            },
          }}
        >
          {data.length &&
            data.map((slide) => {
              return (
                <SwiperSlide key={slide.title}>
                  <div
                    className={classnames(
                      'cr-timeline-swiper__slide',
                      slide.background ? 'cr-is-background' : ''
                    )}
                  >
                    <div
                      className={classnames(
                        'cr-timeline-swiper__inner cr-is-background__text'
                      )}
                    >
                      <h2
                        className={classnames(
                          'cr-timeline-swiper__inner--title cr-h2 cr-underline-gradient'
                        )}
                      >
                        {slide.title}
                      </h2>

                      <p className="cr-timeline-swiper__inner--description cr-reg">
                        <span>{slide.description}</span>
                      </p>

                      {slide.logo && (
                        <div className="cr-timeline-swiper__inner--logo">
                          <img
                            src={slide.logo.sourceUrl}
                            className={'cr-logo-img'}
                            alt={slide.logo.altText}
                          />
                        </div>
                      )}
                    </div>

                    {slide.background && (
                      <div
                        className={
                          'cr-timeline-swiper__inner--background cr-is-background__pic'
                        }
                      >
                        <img
                          src={slide.background.sourceUrl}
                          className="cr-background-img"
                          alt={slide.background.altText}
                        />
                      </div>
                    )}
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </div>
  );
};

export default TimelineSwiper;
