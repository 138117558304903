import React from 'react';
import classnames from 'classnames';
import * as styles from './hero.module.scss';

const index = ({ data }) => {
  return (
    <div className={classnames(styles.hero)}>
      <p className={classnames(styles.subtitle, 'cr-text-3 cr-subheader')}>
        {data.subtitle && data.subtitle}
      </p>

      <h2 className={classnames('cr-h2')}>{data.title && data.title}</h2>
    </div>
  );
};

export default index;
