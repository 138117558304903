// extracted by mini-css-extract-plugin
export var modal = "styles-module--modal--3mTFV";
export var modal_cont = "styles-module--modal_cont--2096I";
export var modal_person = "styles-module--modal_person--1MMvS";
export var modal_person_img = "styles-module--modal_person_img--3AhgC";
export var modal_person_info = "styles-module--modal_person_info--2kwRZ";
export var modal_person_info_title = "styles-module--modal_person_info_title--1MTtY";
export var modal_person_info_pos = "styles-module--modal_person_info_pos--3sV0A";
export var modal_txt = "styles-module--modal_txt--Z7Esg";
export var modal_txt_inner = "styles-module--modal_txt_inner--3Izwp";
export var modal_txt_blocks = "styles-module--modal_txt_blocks--xhjp5";
export var modal_txt_block = "styles-module--modal_txt_block--2u1ym";
export var modal_txt_block_title = "styles-module--modal_txt_block_title--2Ct6z";
export var modal_txt_btn = "styles-module--modal_txt_btn--1tyir";