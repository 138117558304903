import React from 'react';
import Layout from 'sections/Layout';

import Hero from 'sections/pages/Features/Hero';
import Media from 'sections/pages/Features/Media';
import ItemSection from 'sections/pages/Features/ItemSection';
import Cloud from 'sections/pages/Features/Cloud';
import RunDevice from 'sections/pages/Features/RunDevice';
import Registration from 'sections/common/Registration';
import Blog from 'components/Blog';

import MediaVideoPoster from '@/images/features/media/video-poster.png';

const Features = ({ page }) => {
  const {
    template: { features_fields: featuresPage },
    seo,
    blogCategories,
  } = page;

  const HERO_DATA = {
    subheader: featuresPage.heroSubheader,
    title: featuresPage.heroTitle,
    titleHighlighted: featuresPage.heroTitleHighlighted,
    description: featuresPage.heroDescription,
  };

  const MEDIA_DATA = {
    video: '',
    poster: MediaVideoPoster,
    sloganList: (featuresPage.mediaModuleTextItems || []).map(
      ({ slogan }) => slogan
    ),
  };

  const POINT_OF_SALE_DATA = {
    title: featuresPage.itemsectionTitle,
    desc: featuresPage.itemsectionDescription,
    list: (featuresPage.itemsectionListItems || []).map(
      ({ itemDescription, itemTitle, itemIcon }) => ({
        title: itemTitle,
        text: itemDescription,
        iconSrc: itemIcon?.sourceUrl,
      })
    ),
    testimonial: {
      avatar: featuresPage.itemsectionTestimonialsPicture?.sourceUrl,
      bloquote: featuresPage.itemsectionTestimonialsQuote,
      name: featuresPage.itemsectionTestimonialsName,
      position: featuresPage.itemsectionNamePosition,
      logoSrc: featuresPage.itemsectionTestimonialsLogo?.sourceUrl,
    },
    button: {
      text: featuresPage.itemsectionButton?.itemsectionButtonText,
    },
  };

  const MARKETING_DATA = {
    title: featuresPage.itemsection2Title,
    desc: featuresPage.itemsection2Description,
    list: (featuresPage.itemsection2Tiles || []).map(
      ({ tile2Description, tile2Title, tile2Icon }) => ({
        title: tile2Title,
        text: tile2Description,
        iconSrc: tile2Icon?.sourceUrl,
      })
    ),
    testimonial: {
      avatar: featuresPage.itemsection2TestimonialsPicture?.sourceUrl,
      bloquote: featuresPage.itemsection2TestimonialsQuote,
      name: featuresPage.itemsection2TestimonialsName,
      position: featuresPage.itemsection2NamePosition,
      logoSrc: featuresPage.itemsection2TestimonialsLogo?.sourceUrl,
    },
    button: {
      text: featuresPage.itemsection2Button?.itemsection2ButtonText,
    },
  };

  const MANAGEMETNT_DATA = {
    title: featuresPage.itemsection3Title,
    desc: featuresPage.itemsection3Description,
    list: (featuresPage.itemsection3Tiles || []).map(
      ({ tile3Description, tile3Title, tile3Icon }) => ({
        title: tile3Title,
        text: tile3Description,
        iconSrc: tile3Icon?.sourceUrl,
      })
    ),
    testimonial: {
      avatar: featuresPage.itemsection3TestimonialsPicture?.sourceUrl,
      bloquote: featuresPage.itemsection3TestimonialsQuote,
      name: featuresPage.itemsection3TestimonialsName,
      position: featuresPage.itemsection3NamePosition,
      logoSrc: featuresPage.itemsection3TestimonialsLogo?.sourceUrl,
    },
    button: {
      text: featuresPage.itemsection3Button?.itemsection3ButtonText,
    },
  };

  const CLOUD_DATA = {
    title: featuresPage.cloudSectionTitle,
    titleHighlighted: featuresPage.cloudSectionTitleHighlighted,
    advantages: (featuresPage.cloudAdvantageslist || []).map(
      ({ listDescription, listTitle }) => ({
        title: listTitle,
        text: listDescription,
      })
    ),
    benefits: (featuresPage.cloudBenefitsList || []).map(
      ({ benefitslistTitle, benefitslistText }) => ({
        title: benefitslistTitle,
        text: benefitslistText,
      })
    ),
    button: {
      text: featuresPage.cloudButton?.cloudButtonText,
    },
    logos: (featuresPage.cloudLogo || []).map(
      ({ logoItem }) => logoItem?.sourceUrl
    ),
  };

  const RUN_DEVICE_DATA = {
    title: featuresPage.rundeviceTitle,
    desc: featuresPage.rundeviceDescription,
  };

  return (
    <Layout
      currentPageClass={'cr-features-page'}
      headerClass={'cr-features-header'}
      mainClass={'cr-features-main'}
      footerClass={'cr-features-footer'}
      seo={seo}
    >
      {(commonData) => (
        <>
          <Hero data={HERO_DATA} />
          <Media data={MEDIA_DATA} />
          <ItemSection data={POINT_OF_SALE_DATA} id="POS" />
          <ItemSection
            data={MARKETING_DATA}
            className={'cr-reverse'}
            id="Marketing"
          />
          <Cloud data={CLOUD_DATA} />
          <ItemSection data={MANAGEMETNT_DATA} id="Management" />

          <section>
            <RunDevice data={RUN_DEVICE_DATA} />
          </section>

          <section className="cr-wrap">
            <Registration />
          </section>

          <Blog data={blogCategories} />
        </>
      )}
    </Layout>
  );
};

export default Features;
