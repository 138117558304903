import React, { useState } from 'react';
import classnames from 'classnames';
import RadioBtn from 'components/UI/RadioBtn';
import * as styles from './radioBtns.module.scss';

const RadioBtns = ({ data, onChange }) => {
  const [currentSelect, setCurrentSelect] = useState(null);

  return (
    <ul className={classnames(styles.list)}>
      {data.length &&
        data.map((radio, index) => {
          return (
            <li key={radio.position + index}>
              <RadioBtn
                name={'software'}
                isActive={currentSelect === radio.position + index}
                className={classnames(
                  styles.radio,
                  radio.is_active && styles.radio_isActive
                )}
                onChange={() => {
                  setCurrentSelect(radio.position + index);
                  onChange(radio.position);
                }}
              >
                <span className="cr-h4">{radio.position}</span>
              </RadioBtn>
            </li>
          );
        })}
    </ul>
  );
};

export default RadioBtns;
