// extracted by mini-css-extract-plugin
export var info = "styles-module--info--3FOAm";
export var info_top = "styles-module--info_top--3VHRq";
export var info_suptitle = "styles-module--info_suptitle--2GQiY";
export var info_comment = "styles-module--info_comment--Qwyk4";
export var info_center = "styles-module--info_center--1Rqln";
export var info_benefits = "styles-module--info_benefits--2kNj1";
export var info_benefit = "styles-module--info_benefit--Rw38W";
export var info_benefit_type = "styles-module--info_benefit_type--Ejd_2";
export var info_benefit_desc = "styles-module--info_benefit_desc--3esVs";
export var info_bot = "styles-module--info_bot--3DEtJ";
export var info_btns = "styles-module--info_btns--2FqXe";
export var jsAnimInit = "styles-module--jsAnimInit--psDtd";