import React from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

import Icon from '@/components/UI/Icon';

import useGetCirrusoftModal from 'hooks/useGetCirrusoftModal';

const PopupLink = ({ data }) => {
  const { openGetCirrusModal } = useGetCirrusoftModal();

  return (
    <button type="button" className={styles.link} onClick={openGetCirrusModal}>
      <div className={styles.link_l}>
        <p className={classnames('cr-gradient cr-h3', styles.link_title)}>
          {data.title}
        </p>
        <p className={classnames('cr-lead cr-white')}>{data.desc}</p>
      </div>
      <div className={styles.link_r}>
        <div className={styles.link_arrow}>
          <Icon name="corner-arrow-small" size="24px"></Icon>
        </div>
      </div>
    </button>
  );
};

export default PopupLink;
