import React from 'react';
import classnames from 'classnames';
import Icon from 'components/UI/Icon';
import useIntersection from 'hooks/useIntersection';
import * as styles from './styles.module.scss';

const BenefitItem = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.8,
  });

  return (
    <div ref={scrollWrapper}>
      <div
        className={classnames(styles.item, inViewSec && styles.jsAnimInit)}
        ref={inViewRefSec}
      >
        <i className={classnames(styles.icon)}>
          {data.icon ? (
            <img src={data.icon.sourceUrl} alt={data.icon.altText} />
          ) : (
            <Icon name="hardware" />
          )}
        </i>

        <h3 className={classnames(styles.title, 'cr-h3')}>
          {data.title && data.title}
        </h3>

        <p className={classnames('cr-reg')}>
          {data.description && data.description}
        </p>
      </div>
    </div>
  );
};

export default BenefitItem;
