import React, { useMemo } from 'react';

import { getImage, withArtDirection } from 'gatsby-plugin-image';

import ServiceHero from 'sections/common/ServiceHero';
import useStaticImage from 'hooks/graphql/useStaticImage';

import * as styles from './styles.module.scss';

const GasStationsHero = ({ data }) => {
  const getStaticImage = useStaticImage();

  const posters = useMemo(
    () =>
      withArtDirection(
        getImage(
          getStaticImage('gas-stations/hero/hero-bg-desktop.png')
            .childImageSharp
        ),
        [
          {
            media: '(min-width: 577px) and (max-width: 1200px)',
            image: getImage(
              getStaticImage('gas-stations/hero/hero-bg-tablet.png')
                .childImageSharp
            ),
          },
          {
            media: '(max-width: 576px)',
            image: getImage(
              getStaticImage('gas-stations/hero/hero-bg-mobile.png')
                .childImageSharp
            ),
          },
        ]
      ),
    [getStaticImage]
  );

  return (
    <ServiceHero bgClassName={styles.hero_bg} data={data} posters={posters} />
  );
};

export default GasStationsHero;
