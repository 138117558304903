import React, { useState, useRef, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';
import Icon from 'components/UI/Icon';

const Search = ({ onChange = () => {}, value = '', className }) => {
  const [isOpen, setIsOpen] = useState(!!value);
  const [curValue, setCurValue] = useState(value);
  const inputRef = useRef(null);

  useEffect(() => {
    if (value === curValue) return;

    setCurValue(value);
  }, [value]); // eslint-disable-line

  const btnClickHandler = useCallback(() => {
    setIsOpen(true);

    setTimeout(() => {
      inputRef.current.focus();
    });
  }, [setIsOpen, inputRef]);

  const inputBlurHandler = useCallback(() => {
    if (curValue) return;

    setIsOpen(false);
  }, [setIsOpen, curValue]);

  const inputOnChangeHandler = useCallback(
    (e) => {
      const { value } = e.target;
      setCurValue(value);
      onChange(value);
    },
    [setCurValue, onChange]
  );

  return (
    <div className={classnames(styles.search, className)}>
      {isOpen ? (
        <input
          type="text"
          placeholder="Search"
          value={curValue}
          ref={inputRef}
          className={styles.search_input}
          onChange={inputOnChangeHandler}
          onBlur={inputBlurHandler}
        />
      ) : (
        <button
          type="button"
          className={styles.search_btn}
          onClick={btnClickHandler}
        >
          <Icon name={'search'} className="cr-text-3" />
        </button>
      )}
    </div>
  );
};

export default Search;
