import React from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

import PartnerLink from './components/PartnerLink';

import useIntersection from 'hooks/useIntersection';

const PartnerLinks = ({ curPartner, onPartnerClick, partners }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.6,
  });

  return (
    <div
      className={classnames(styles.wrap, inViewSec && styles.jsAnimInit)}
      ref={scrollWrapper}
    >
      <ul className={styles.links} ref={inViewRefSec}>
        {partners.map((partner) => (
          <li key={partner.id}>
            <PartnerLink
              img={partner.logo}
              isActive={curPartner.id === partner.id}
              onClick={() => {
                onPartnerClick(partner);
              }}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PartnerLinks;
