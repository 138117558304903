import React from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

import Icon from 'components/UI/Icon';
import WpImage from 'components/Elements/WPImage';

import useIntersection from 'hooks/useIntersection';
import { DESKTOP_KEY } from 'constants/devices.js';
import useDeviceState from 'hooks/useDeviceState';
import useReusableBlocks from 'hooks/graphql/useReusableBlocks';

const Employee = ({ employee, onClick = () => {} }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.2,
  });
  const { forms_ui: data } = useReusableBlocks();
  const deviceState = useDeviceState();

  return (
    <figure
      className={classnames(styles.employee, {
        [styles.jsAnimInit]: inViewSec && deviceState !== DESKTOP_KEY,
      })}
      onClick={() => {
        onClick(employee);
      }}
      ref={scrollWrapper}
    >
      <div className={styles.employee_img} ref={inViewRefSec}>
        <WpImage src={employee.photo} alt={employee.name} />
      </div>

      <figcaption className={styles.employee_info}>
        <p className={classnames('cr-h5 cr-txt-up', styles.employee_info_name)}>
          {employee.name}
        </p>
        <p className={classnames('cr-reg', styles.employee_info_pos)}>
          {employee.position}
        </p>

        <div className={styles.employee_info_btns}>
          <a
            href={employee.socLinkUrl}
            target="_blank"
            rel="noreferrer"
            className="cr-btn cr-btn--white cr-btn--connect cr-btn--connect-blue cr-btn--small"
          >
            <Icon name="linkedIn" size="16px" />
            <span>{data.buttons.connect}</span>
          </a>

          <div className={styles.employee_info_btns_arrow}>
            <Icon name={'arrow-right'} className="cr-white" />
          </div>
        </div>
      </figcaption>
    </figure>
  );
};

export default Employee;
