import React from 'react';
import { getImage } from 'gatsby-plugin-image';

import Layout from '@/sections/Layout';
import Hero from '@/sections/pages/ConvenienceRetail/Hero';
import Solutions from '@/sections/common/Solutions';
import InfoGraphics from '@/sections/common/ServiceInfoGraphics';
import Transactions from '@/sections/pages/ConvenienceRetail/Transactions';
import Crossplatform from '@/sections/common/Crossplatform';
import Partners from '@/sections/common/Partners';
import Benefits from '@/sections/common/Benefits';
import Registration from '@/sections/common/Registration';
import Blog from 'components/Blog';

import useStaticImage from 'hooks/graphql/useStaticImage';

import {
  parseServiceBenefitsTable,
  parseServicePartnersBtns,
  parseServicePartnersList,
  parseInfoGraphicList,
  parseInfoGraphicBtn,
} from './parseFuncs';

import '@/sections/pages/ConvenienceRetail/styles.scss';

const ConvenienceRetail = ({ page }) => {
  const getStaticImage = useStaticImage();

  const {
    template: { convenince_retails_fields: convRetailPage },
    seo,
    blogCategories,
  } = page;

  const HERO_DATA = {
    title: convRetailPage.heroTitle,
    titleHighlighted: convRetailPage.heroTitleHighlighted,
    subtitle: convRetailPage.heroSubtitle,
    list: (convRetailPage.heroList || []).map(
      ({ heroListItem }) => heroListItem
    ),
  };

  const SOLUTION_DATA = {
    title: convRetailPage.solutionsTitle,
    subtitle: convRetailPage.solutionsSubtitle,
    list: (convRetailPage.solutionsCardlist || []).map(
      ({ itemIcon, itemText }) => ({
        title: itemText,
        iconSrc: itemIcon?.sourceUrl,
      })
    ),
  };

  const INFO_GRAPHICS_DATA_1 = {
    title: convRetailPage.infograph1Title,
    suptitle: convRetailPage.infograph1Subtitle,
    mediaSrc: getImage(
      getStaticImage('conv-retail/info-graphics/img1.png').childImageSharp
    ),
    list: parseInfoGraphicList(convRetailPage.infograph1TextItems),
    btn1: parseInfoGraphicBtn(convRetailPage.infograph1Button1),
    btn2: parseInfoGraphicBtn(convRetailPage.infograph1Button2),
  };

  const INFO_GRAPHICS_DATA_2 = {
    title: convRetailPage.infograph2Title,
    suptitle: convRetailPage.infograph2Subtitle,
    mediaSrc: getImage(
      getStaticImage('conv-retail/info-graphics/img2.png').childImageSharp
    ),
    list: parseInfoGraphicList(convRetailPage.infograph2TextItems),
    btn1: parseInfoGraphicBtn(convRetailPage.infograph2Button1),
    btn2: parseInfoGraphicBtn(convRetailPage.infograph2Button2),
    isReversed: true,
  };

  const INFO_GRAPHICS_DATA_3 = {
    title: convRetailPage.infograph3Title,
    suptitle: convRetailPage.infograph3Subtitle,
    mediaSrc: getImage(
      getStaticImage('conv-retail/info-graphics/img3.png').childImageSharp
    ),
    list: parseInfoGraphicList(convRetailPage.infograph3TextItems),
    btn1: parseInfoGraphicBtn(convRetailPage.infograph3Button1),
    btn2: parseInfoGraphicBtn(convRetailPage.infograph3Button2),
  };

  const TRANSACTION_DATA = {
    card: {
      title: convRetailPage.transTitle,
      desc: convRetailPage.transLead,
    },
  };

  const CROSSPLATFORM_DATA = {
    title: convRetailPage.crossplatTitle,
    desc: convRetailPage.crossplatLead,
  };

  const PARTNERS_DATA = {
    partnersSubheader: convRetailPage.partnersSubtitle,
    partnersBtns: parseServicePartnersBtns(convRetailPage.partnersInfoButtons),
    partnersList: parseServicePartnersList(convRetailPage.partners),
  };

  const BENEFITS_DATA = {
    title: convRetailPage.benefitsTitle,
    benefitsTh: convRetailPage.benefitsTableTh,
    benefits: parseServiceBenefitsTable(convRetailPage.benefitsTable),
  };

  return (
    <Layout
      currentPageClass={'cr-conv-retail-page'}
      headerClass={'cr-conv-retail-header'}
      mainClass={'cr-conv-retail-main'}
      footerClass={'cr-conv-retail-footer'}
      seo={seo}
    >
      {(commonData) => (
        <>
          <section className="cr-conv-retail__hero">
            <Hero data={HERO_DATA} />
          </section>

          <section className="cr-wrap cr-conv-retail__solutions">
            <Solutions data={SOLUTION_DATA} />
          </section>

          <section className="cr-wrap cr-conv-retail__info-grap">
            <InfoGraphics data={INFO_GRAPHICS_DATA_1} />
          </section>

          <section className="cr-wrap cr-conv-retail__info-grap">
            <InfoGraphics data={INFO_GRAPHICS_DATA_2} />
          </section>

          <section className="cr-wrap cr-conv-retail__info-grap">
            <InfoGraphics data={INFO_GRAPHICS_DATA_3} />
          </section>

          <section className="cr-conv-retail__trans">
            <Transactions data={TRANSACTION_DATA} />
          </section>

          <section className="cr-wrap cr-conv-retail__cross-plat">
            <Crossplatform data={CROSSPLATFORM_DATA} />
          </section>

          <section className="cr-wrap cr-conv-retail__partners">
            <Partners data={PARTNERS_DATA} />
          </section>

          <section className="cr-wrap cr-conv-retail__benefits">
            <Benefits data={BENEFITS_DATA} />
          </section>

          <section className="cr-wrap cr-conv-retail__registr">
            <Registration />
          </section>

          <Blog data={blogCategories} noMargin={true} />
        </>
      )}
    </Layout>
  );
};

export default ConvenienceRetail;
