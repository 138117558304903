import React from 'react';
import classnames from 'classnames';
import Accordeon from '../Accordeon';
import * as styles from './solutionMobile.module.scss';

const SolutionMobile = ({ data }) => {
  return (
    <section className={classnames(styles.container)}>
      <div className={classnames('cr-wrap')}>
        <div>
          <p className={classnames(styles.subtitle, 'cr-subheader cr-text-3')}>
            {data.subtitle && data.subtitle}
          </p>
          <h2 className={classnames(styles.title, 'cr-h2')}>{data.title}</h2>
        </div>
        <div>
          <Accordeon accordeon={data.accordeon} />
        </div>
      </div>
    </section>
  );
};

export default SolutionMobile;
