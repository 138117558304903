import React from 'react';
import Layout from 'sections/Layout';

import Hero from 'sections/pages/Careers/Hero';
import Reasons from 'sections/pages/Careers/Reasons';
import Banner from 'sections/common/Banner';
import Opportunities from 'sections/pages/Careers/Opportunities';
import Connect from 'sections/common/Connect';
import Blog from 'components/Blog';

const Careers = ({ page }) => {
  const {
    template: { careers },
    seo,
    blogCategories,
  } = page;

  const HERO_DATA = {
    title: careers.heroTitle,
    subtitle: careers.heroSubtitle,
    description: careers.heroDescription,
  };

  const REASONS_DATA = {
    title: careers.reasonsSubtitle,
    subtitle: careers.reasonsTitle,
    list: (careers.reasonsTilesItems || []).map(
      ({
        reasonTileItemTitle,
        reasonTileItemDescription,
        reasonTileItemImage,
      }) => ({
        title: reasonTileItemTitle,
        description: reasonTileItemDescription,
        icon: reasonTileItemImage?.sourceUrl,
      })
    ),
  };

  const BANNER_DATA = {
    image: careers.banner.image,
    description: careers.banner.description,
  };

  const JOIN_DATA = {
    title: careers.join.title,
    subtitle: careers.join.subtitle,
    accordion: careers.join.options,
  };

  return (
    <Layout
      currentPageClass={'cr-careers-page'}
      headerClass={'cr-careers-header'}
      mainClass={'cr-careers-main'}
      footerClass={'cr-careers-footer'}
      seo={seo}
    >
      {(commonData) => (
        <>
          <Hero data={HERO_DATA} />
          <Reasons data={REASONS_DATA} />
          <Banner data={BANNER_DATA} />
          <Opportunities data={JOIN_DATA} />
          <Connect />
          <Blog data={blogCategories} />
        </>
      )}
    </Layout>
  );
};

export default Careers;
