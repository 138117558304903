import React from 'react';
import classnames from 'classnames';
import * as styles from './hero.module.scss';

const Hero = ({ data }) => {
  return (
    <div className={classnames(styles.hero)}>
      <h3 className={classnames(styles.title, 'cr-h3')}>
        {data.area && data.area}
      </h3>

      <p className="cr-reg">{data.description}</p>
    </div>
  );
};

export default Hero;
