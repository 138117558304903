// extracted by mini-css-extract-plugin
export var questions = "styles-module--questions--1ScaH";
export var questions_inner = "styles-module--questions_inner--22UgU";
export var questions_inner_head = "styles-module--questions_inner_head--1yKHy";
export var questions_inner_img = "styles-module--questions_inner_img--23o62";
export var questions_inner_swiper = "styles-module--questions_inner_swiper--1uHWl";
export var questions_inner_bg_1 = "styles-module--questions_inner_bg_1--2HszM";
export var questions_inner_bg_2 = "styles-module--questions_inner_bg_2--dJIWm";
export var jsAnimInit = "styles-module--jsAnimInit--302j1";
export var questions_txt_suptitle = "styles-module--questions_txt_suptitle--22uXy";
export var questions_img = "styles-module--questions_img--1KwjL";