import React, { useRef, useEffect } from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Navigation, Pagination } from 'swiper';

import QuestionSlide from './components/Slide';
import PopupLink from './components/PopupLink';
import SliderBtn from 'components/UI/SliderBtn';
import SwiperPagin from 'components/UI/SwiperPagin';

import useIntersection from 'hooks/useIntersection';

SwiperCore.use([Navigation, Pagination]);

// TODO: реализовать hook для swiper
const QuestionsSwiper = React.memo(({ slides, popupData }) => {
  const containerRef = useRef(null);
  const prevBtnRef = useRef(null);
  const nextBtnRef = useRef(null);
  const paginRef = useRef(null);

  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.3,
  });

  // TODO: Отключать свайпер при влезании в компенент
  useEffect(() => {
    const swiper = new Swiper(containerRef.current, {
      direction: 'vertical',
      slidesPerView: 'auto',
      spaceBetween: 0,
      pagination: {
        el: paginRef.current,
      },
      navigation: {
        nextEl: nextBtnRef.current,
        prevEl: prevBtnRef.current,
      },
    });

    return () => {
      swiper.destroy(true, true);
    };
  }, []);

  return (
    <div ref={scrollWrapper}>
      <div
        ref={containerRef}
        className={classnames('swiper-container', styles.swiper_main)}
      >
        <div
          className={classnames(styles.swiperWrapper, 'swiper-wrapper', {
            [styles.jsAnimInit]: inViewSec,
          })}
          ref={inViewRefSec}
        >
          {slides.map((questionItem, i) => (
            <div
              key={i}
              className={classnames(styles.swiperWrap, 'swiper-slide')}
            >
              <QuestionSlide slide={questionItem} index={i} />
            </div>
          ))}

          <div className="swiper-slide">
            <PopupLink data={popupData} />
          </div>
        </div>
      </div>

      <div>
        <SliderBtn
          ref={prevBtnRef}
          className={styles.swiper_btn}
          type="up"
          mod="dark"
        />

        <SliderBtn
          ref={nextBtnRef}
          className={styles.swiper_btn}
          type="down"
          mod="dark"
        />

        <SwiperPagin ref={paginRef} className={styles.swiper_pagin} />
      </div>
    </div>
  );
});

export default QuestionsSwiper;
