import React from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

const Card = ({ card }) => (
  <div className={styles.card}>
    <div className={styles.card_inner}>
      <img src={card.iconSrc} className={styles.card_icon} alt="icon" />
      <p className={classnames('cr-reg', styles.card_title)}>{card.title}</p>
    </div>
  </div>
);

export default Card;
