import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import Layout from 'sections/Layout';
import Header from 'sections/pages/Blog/Header';
import HeadNews from 'sections/pages/Blog/HeadNews';
import Aside from 'sections/pages/Blog/Aside';
import News from 'sections/pages/Blog/News';
import Join from 'sections/common/Join';
import EmptyState from 'sections/pages/Blog/EmptyState';
import Button from 'components/UI/Button';
import Icon from 'components/UI/Icon';

import 'sections/pages/Blog/styles.scss';

import useBlogPosts from 'hooks/graphql/useBlogPosts';
import useCategories from 'hooks/graphql/useCategories';
import useTags from 'hooks/graphql/useTags';

import { parseQuery, stringifyQuery, filterQuery } from '@/assets/js/query';

const Blog = ({ page }) => {
  const {
    template: { blog: blogPage },
    seo,
  } = page;

  const location = useLocation();
  const categories = useCategories();
  const tags = useTags();
  const allPosts = useBlogPosts();

  const [query, setQuery] = useState({
    page: 1,
    perPage: 5,
    search: {},
  });

  const filteredPosts = useMemo(() => {
    const { search } = query;

    return allPosts
      .filter(
        ({ title }) =>
          !search.title ||
          title.toLowerCase().includes(search.title.toLowerCase())
      )
      .filter(
        ({ categories }) =>
          !search.category ||
          categories.nodes.find(({ slug }) => slug === search.category)
      )
      .filter(
        ({ tags }) =>
          !search.tag || tags.nodes.find(({ slug }) => slug === search.tag)
      );
  }, [allPosts, query]);

  const curPosts = useMemo(() => {
    const { page, perPage } = query;
    const postsCount = page * perPage;

    return filteredPosts.slice(0, postsCount);
  }, [query, filteredPosts]);

  const headPost = useMemo(() => curPosts[0], [curPosts]);
  const otherPosts = useMemo(() => curPosts.slice(1), [curPosts]);

  useEffect(() => {
    setQuery((query) => ({
      ...query,
      search: parseQuery(location.search),
    }));
  }, [location.search]);

  const changeQuerySearchHandler = useCallback(
    (newSearch) => {
      const curSearch = parseQuery(location.search);
      const queryObj = filterQuery({ ...curSearch, ...newSearch });
      const query = stringifyQuery(queryObj);
      navigate(`${location.pathname}?${query}`, { replace: true });
    },
    [location.search, location.pathname]
  );

  const showMoreBtnClickHandler = useCallback(() => {
    setQuery((query) => ({
      ...query,
      page: query.page + 1,
    }));
  }, [setQuery]);

  const isLastPage = useMemo(() => {
    return curPosts.length === filteredPosts.length;
  }, [curPosts, filteredPosts]);

  const HEADER_DATA = {
    subtitle: blogPage.subtitlePageName,
    title: blogPage.title,
  };

  const asideData = useMemo(
    () => ({
      categoriesTitle: blogPage.filterCategoriesTitle,
      categories: categories.filter(({ slug }) =>
        filteredPosts.some((post) =>
          post.categories.nodes.some((postCat) => postCat.slug === slug)
        )
      ),
      tagsTitle: blogPage.filterPopularTitle,
      tags: tags.filter(({ slug }) =>
        filteredPosts.some((post) =>
          post.tags.nodes.some((postTag) => postTag.slug === slug)
        )
      ),
    }),
    [blogPage, categories, tags, filteredPosts]
  );

  return (
    <Layout
      currentPageClass={'cr-blog-page'}
      headerClass={'cr-blog-header'}
      mainClass={'cr-blog-main'}
      footerClass={'cr-blog-footer'}
      seo={seo}
    >
      {(commonData) => (
        <>
          <div className="cr-wrap cr-blog-main-inner">
            <section className="cr-blog-main-inner__header">
              <Header
                data={HEADER_DATA}
                search={query.search}
                onFilter={changeQuerySearchHandler}
              />
            </section>

            <section className="cr-blog-main-inner__head-news">
              {headPost ? <HeadNews post={headPost} /> : <EmptyState />}
            </section>

            <aside className="cr-blog-main-inner__aside">
              <Aside
                data={asideData}
                search={query.search}
                onFilter={changeQuerySearchHandler}
              />
            </aside>

            <section className="cr-blog-main-inner__news">
              <News data={otherPosts} />

              {!isLastPage && (
                <div className="cr-blog-main-inner__news-btn">
                  <Button
                    className="cr-btn--large cr-btn--wide"
                    onClick={showMoreBtnClickHandler}
                  >
                    Show more
                    <Icon name="arrow-down" />
                  </Button>
                </div>
              )}
            </section>
          </div>

          <div className="cr-blog-main-inner__join">
            <Join />
          </div>
        </>
      )}
    </Layout>
  );
};

export default Blog;
