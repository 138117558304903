import React from 'react';
import classnames from 'classnames';
import useIntersection from 'hooks/useIntersection';
import * as styles from './styles.module.scss';
import { GatsbyImage } from 'gatsby-plugin-image';

import '@/styles/common/_hero.scss';

const Hero = ({ data, bgClassName, posters }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.6,
  });

  return (
    <div className={styles.hero} ref={scrollWrapper}>
      <div className={classnames(styles.hero_bg, bgClassName)}>
        <GatsbyImage image={posters} alt={data.title} />
      </div>
      <div
        className={classnames('cr-wrap', styles.hero_inner, {
          [styles.jsAnimInit]: inViewSec,
        })}
      >
        <div className={classnames('cr-common-hero-subtitle', styles.hero_top)}>
          <p className={classnames('cr-subheader cr-text-3')}>
            {data.subtitle}
          </p>
        </div>

        <div className={styles.hero_center} ref={inViewRefSec}>
          <h1 className={classnames('cr-h1', styles.hero_title)}>
            <span className={classnames('cr-white')}>{data.title}</span>
            <span className={classnames('cr-gradient')}>
              {data.titleHighlighted}
            </span>
          </h1>
        </div>

        <div className={styles.hero_bot}>
          <ul
            className={classnames(
              'cr-lead cr-white cr-checked-list',
              styles.hero_list
            )}
          >
            {data.list.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Hero;
