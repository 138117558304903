// extracted by mini-css-extract-plugin
export var section = "itemSection-module--section--2mrkV";
export var inner = "itemSection-module--inner--3T5Qi";
export var header = "itemSection-module--header--2qBS_";
export var header_title = "itemSection-module--header_title--3YLOz";
export var jsAnimInit = "itemSection-module--jsAnimInit--1Hsrz";
export var flexContainer = "itemSection-module--flexContainer--1Slvw";
export var flexContainer_list = "itemSection-module--flexContainer_list--2PIet";
export var flexContainer_bloquote = "itemSection-module--flexContainer_bloquote--3IxcB";
export var linkWrap = "itemSection-module--linkWrap--1wSqk";
export var header_description = "itemSection-module--header_description--ljIDi";