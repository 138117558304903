import React from 'react';
import classnames from 'classnames';
import Icon from 'components/UI/Icon';
import * as styles from './success.module.scss';

const Success = () => {
  return (
    <div>
      <p className={classnames(styles.subtitle)}>
        <i className={classnames(styles.subtitle_icon)}>
          <Icon name="circle-check" />
        </i>
        <span
          className={classnames(styles.subtitle_text, 'cr-txt-up cr-text-3')}
        >
          your CV sent
        </span>
      </p>

      <h2 className={classnames(styles.title, 'cr-h2')}>
        Thank you for your request.
      </h2>

      <p className="cr-reg">Our recruiting team will contact you shortly</p>
    </div>
  );
};

export default Success;
