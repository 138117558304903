import React from 'react';
import classnames from 'classnames';

import * as styles from './Contact.module.scss';
import FormContact from 'components/Forms/Contact';
import Address from './components/Address';
import useReusableBlocks from 'hooks/graphql/useReusableBlocks';
import Icon from 'components/UI/Icon';

import useContactOnSite from 'hooks/graphql/useContactsSite';

const Contact = ({ data, ...props }) => {
  const reusableBlocks = useReusableBlocks();
  const contacts = useContactOnSite();

  const formCommonData = {
    placeholders: {
      ...reusableBlocks.forms_ui.placeholders,
      topic: reusableBlocks.topicForMessageSelector.placeholder,
      termsConditions: reusableBlocks.forms_ui.termsAndConditions,
      submitButton: reusableBlocks.forms_ui.buttons.submit,
    },
    texts: {
      title: data.formTitle,
      subtitle: data.formSubtitle,
    },
    options:
      reusableBlocks.topicForMessageSelector?.options?.map(({ topic }) => ({
        option: topic,
        value: topic,
      })) || [],
    finish: reusableBlocks.forms_ui.success,
  };

  return (
    <div className={classnames('cr-wrap', styles.wrap)}>
      <div className={styles.top}>
        <h1 className="cr-h1">{data.title}</h1>
        <p className="cr-lead">{data.subtitle}</p>
      </div>
      {data.location && data.location.length && (
        <div className={styles.addresses}>
          {data.location.map((loc, index) => (
            <Address key={index} data={loc} />
          ))}
        </div>
      )}
      <div className={styles.bottom}>
        <div className={styles.bottom_left}>
          <FormContact data={formCommonData} />
        </div>
        <div className={styles.bottom_right}>
          <div className={styles.help}>
            <Icon name="headphones" />
            <p className="cr-subheader-2">{data.imageTitle}</p>
            <span className="cr-text-2">{data.imageSubtitle}</span>
          </div>
          <div className={styles.contacts}>
            {contacts.map(({ type, value, href }) => (
              <a
                key={href}
                href={href}
                className={classnames(
                  'cr-lead',
                  type === 'email'
                    ? styles.contacts_email
                    : styles.contacts_phone
                )}
                target="_blank"
                rel="noreferrer"
              >
                {value}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
