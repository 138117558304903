import React from 'react';
import Header from './components/Header';
import Partners from 'components/Elements/Partners';
import SwiperContainer from './components/SwiperContainer';
import VideoBG from 'assets/videos/bg_gas.mp4';

// import classnames from 'classnames';
import * as styles from './styles.module.scss';

const Testimonials = ({ data }) => (
  <section className={styles.testimonials}>
    <div className="cr-wrap">
      <Header title={data.title} subtitle={data.subtitle} />
      <Partners logos={data.logos} />
      <SwiperContainer testimonials={data.slides} />
    </div>

    <video
      src={VideoBG}
      autoPlay="autoplay"
      loop="loop"
      className={styles.testimonials_video}
    ></video>
  </section>
);

export default Testimonials;
