import React, { useState, useMemo, useEffect } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';

import * as styles from './Map.module.scss';
import CustomMarker from './CustomMarker';
import Link from 'components/UI/Link';
import Icon from 'components/UI/Icon';
import GoogleConstants from 'constants/google';

const getGeocodePlaceByAddress = (address) => {
  if (!window.google) return;

  const GOOGLE_MAPS = window.google.maps;
  const geocoder = new GOOGLE_MAPS.Geocoder();

  return new Promise((res, rej) => {
    geocoder.geocode({ address }, (results, status) => {
      if (status === GOOGLE_MAPS.GeocoderStatus.OK) {
        res(results);
      } else {
        // rej('No geodata ' + status);
      }
    });
  });
};

const GoogleMapComponent = withScriptjs(
  withGoogleMap(({ data, ...props }) => {
    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const [externalLink, setExternalLink] = useState('');

    let marker = useMemo(() => {
      return {
        ...data,
        ...center,
      };
    }, [data, center]);

    useEffect(() => {
      getGeocodePlaceByAddress(
        `${data.street}, ${data.zip}, ${data.city}, ${data.country}`
      ).then((res) => {
        const geoDataResult = res[0];

        if (!geoDataResult) return;

        const {
          location: { lat, lng },
        } = geoDataResult.geometry;

        setCenter({
          lat: lat(),
          lng: lng(),
        });

        if (geoDataResult.formatted_address)
          setExternalLink(
            `${GoogleConstants.maps_domain}${geoDataResult.formatted_address}`
          );
      });
    }, [data.city, data.country, data.street, data.zip]);

    return (
      <div>
        <GoogleMap
          center={center}
          defaultZoom={11}
          defaultOptions={{
            fullscreenControl: false,
            streetViewControl: false,
            scrollwheel: false,
            mapTypeControl: false,
            clickableIcons: false,
            minZoom: 6,
            zoomControlOptions: {
              position: window.google.maps.ControlPosition.RIGHT_TOP,
            },
            disableDefaultUI: true,
          }}
        >
          <MarkerClusterer
            averageCenter
            enableRetinaIcons
            gridSize={3}
            styles={[
              {
                height: 50,
                width: 40,
                textColor: 'white',
                textSize: 13,
              },
            ]}
          >
            <CustomMarker marker={marker} />
          </MarkerClusterer>
        </GoogleMap>

        {externalLink && (
          <Link to={externalLink} target="_blank">
            <span className={styles.link}>
              <Icon name="external-link" />
            </span>
          </Link>
        )}
      </div>
    );
  })
);

export default GoogleMapComponent;
