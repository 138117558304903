import React from 'react';
import classnames from 'classnames';
import useIntersection from 'hooks/useIntersection';
import * as styles from './content.module.scss';

const Content = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.5,
  });

  return (
    <div ref={scrollWrapper}>
      <div
        className={classnames(styles.content, inViewSec && styles.jsAnimInit)}
        ref={inViewRefSec}
      >
        <div className={classnames(styles.hero)}>
          <h2
            className={classnames(
              styles.title,
              'cr-h2 cr-subtitle-sec cr-underline-gradient'
            )}
          >
            {data.title}

            <span className={classnames(styles.title_markered, 'cr-gradient')}>
              {data.title_markered}
            </span>
          </h2>
          <p className={classnames('cr-lead')}>{data.description}</p>
        </div>

        <ul className={classnames(styles.list)}>
          {data.list.map((item, index) => {
            return (
              <li key={index}>
                <img src={item.logoImage.sourceUrl} alt="" />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Content;
