import React from 'react';
import classnames from 'classnames';
import parse, { domToReact } from 'html-react-parser';

import WpImage from 'components/Elements/WPImage';

import * as styles from './Terms.module.scss';

const options = {
  replace: (node) => {
    if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(node.name)) {
      return (
        <h2 className={classnames('cr-h3', styles.title)}>
          {domToReact(node.children, options)}
        </h2>
      );
    }

    if (node.name === 'p') {
      const image = getImage(node);

      if (image != null) {
        return (
          <div>
            <WpImage
              src={image.attribs.src}
              alt={image.attribs.alt}
              width={image.attribs.width}
            />
          </div>
        );
      }
    }

    if (node.name === 'ul') {
      return (
        <ul className={classnames('cr-dotted-list ', styles.list)}>
          {domToReact(node.children, options)}
        </ul>
      );
    }

    if (node.name === 'ol') {
      return (
        <ol className={classnames('cr-num-list ', styles.list)}>
          {domToReact(node.children, options)}
        </ol>
      );
    }

    return node;
  },
};

const getImage = (node) => {
  if (node.name === 'img') {
    return node;
  } else if (node.children != null) {
    for (let index = 0; index < node.children.length; index++) {
      const image = getImage(node.children[index]);

      if (image != null) return image;
    }
  }
};

const Terms = ({ data }) => {
  const content = parse(data.content || '', options);

  return (
    <div className={classnames(styles.wrap, 'cr-wrap')}>
      <div className={styles.top}>
        <h1 className="cr-h2">{data.title && data.title}</h1>
        <p className="cr-lead cr-text-2">{data.subtitle && data.subtitle}</p>
      </div>

      <div className={styles.content}>{content}</div>
    </div>
  );
};

export default Terms;
