import React, { useMemo } from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';
import NewsList from './components/NewsList';
import Subscribe from '@/sections/common/Subscribe';

const News = ({ data }) => {
  const SPLIT_NEWS_INDEX = 3;
  const firstNews = useMemo(() => data.slice(0, SPLIT_NEWS_INDEX), [data]);
  const secNews = useMemo(() => data.slice(SPLIT_NEWS_INDEX), [data]);

  return (
    <div>
      <h2 className={classnames('cr-h3', styles.news_title)}>{data.title}</h2>

      <NewsList news={firstNews} />

      <div className={styles.news_subscribe}>
        <Subscribe />
      </div>

      <NewsList news={secNews} />
    </div>
  );
};

export default News;
