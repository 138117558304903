const gasStationsPage = (WpTag) => `
  heroTitle
  heroTitleHighlighted
  heroSubtitle
  heroListItems {
    heroListText
  }
  solutionsTitle
  solutionsSubtitle
  solutionSwipeItems {
    solutionSwipeText
    solutionSwipeIcon {
      sourceUrl
    }
  }
  partnersSubheader
  partnersInfoButtons {
    partnersInfoButtonText
    partnersInfoButtonsLink
  }
  partners {
    partnerImage {
      sourceUrl
    }
    partnerPosition
    partnerName
    partnerInfoComment
    partnerBenefitItems {
      benefitDescription
      benefitType
    }
    partnerLogos {
      sourceUrl
    }
  }
  statItems {
    statItemNumber
    statItemText
  }
  ecosystemTitle
  ecosystemTextDescription
  ecosystemButton {
    buttonText
  }
  ecosystemCards {
    cloudBased {
      title
      icon {
        sourceUrl
      }
    }
    hardware {
      title
      icon {
        sourceUrl
      }
    }
    integration {
      title
      icon {
        sourceUrl
      }
    }
    modularity {
      title
      icon {
        sourceUrl
      }
    }
    partnership {
      title
      icon {
        sourceUrl
      }
    }
    pricing {
      title
      icon {
        sourceUrl
      }
    }
    privacy {
      title
      icon {
        sourceUrl
      }
    }
    protection {
      title
      icon {
        sourceUrl
      }
    }
    reports {
      title
      icon {
        sourceUrl
      }
    }
    setups {
      title
      icon {
        sourceUrl
      }
    }
    standart {
      title
      icon {
        sourceUrl
      }
    }
  }
  transTitle
  transactionLead
  benefitsTitle
  benefitsTableTh {
    cirrus
    traditional
  }
  benefitsTable {
    tdCirrus
    tdCirrusHighlighted
    tdIconText
    tdTraditional
    tdIcon {
      sourceUrl
    }
  }
  questionsTitle
  questionsSubtitle
  questionsSwiperSlideItems {
    slideQuestion
    slideTopAnswer
  }
  questionsPopupBtn {
    title
    description
  }
  newsTitle
`;

module.exports.gasStationsPage = gasStationsPage;
