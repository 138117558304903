import React from 'react';

import Layout from 'sections/Layout';
import Hero from 'sections/pages/Home/Hero';
import Solution from 'sections/pages/Home/Solution';
import Cloud from 'sections/pages/Home/Cloud';
import Features from 'sections/pages/Home/Features';
import Testimonials from 'sections/pages/Home/Testimonials';
import Join from 'sections/common/Join';
import Blog from 'components/Blog';

import videoIphone from 'assets/videos/solution/iphone.mp4';
import videoPos from 'assets/videos/solution/pos.mp4';
import videoScreenPopup from 'assets/videos/solution/screen_popup.mp4';
import videoStartScreenSystem from 'assets/videos/solution/start_screen_system.mp4';
import videoPopups from 'assets/videos/solution/popups.mp4';

const Home = ({ page }) => {
  const {
    template: { homepage },
    seo,
  } = page;

  const HERO_DATA = {
    title: homepage.heroTitle,
    titleHighlighted: homepage.heroTitleHighlighted,
    description: homepage.heroDescription,
    tiles: homepage.heroTilesItems,
  };

  const SOLUTION_VIDEOS = [
    videoIphone,
    videoPos,
    videoScreenPopup,
    videoStartScreenSystem,
    videoPopups,
  ];

  const SOLUTION_DATA = {
    title: homepage.solutionTitle,
    subtitle: homepage.solutionSubtitle,
    accordeon: homepage.solutionAccordeonItems.map((item, i) => ({
      ...item,
      videoSrc: SOLUTION_VIDEOS[i] ? SOLUTION_VIDEOS[i] : '#',
    })),
  };

  const CLOUD_DATA = {
    title: homepage.cloudTitle,
    subtitle: homepage.cloudSubtitle,
    description: homepage.cloudDescription,
    logos: homepage.cloudLogos,
    btnUrl: homepage.cloudLink,
    btnText: homepage.cloudLinkText,
  };

  const FEATURES_DATA = {
    title: homepage.featuresTitle,
    subtitle: homepage.featuresSubtitle,
    links: (homepage.featuresLinks || []).map(
      ({ featuresLinkText, featuresLinkSrc }) => ({
        txt: featuresLinkText,
        to: featuresLinkSrc?.uri,
      })
    ),
    list: (homepage.featuresListItems || []).map(
      ({
        featuresListDescription,
        featuresListIcon,
        featuresListLink,
        featuresListTitle,
      }) => ({
        title: featuresListTitle,
        description: featuresListDescription,
        url: featuresListLink.link,
        icon: featuresListIcon?.sourceUrl,
      })
    ),
  };

  const TESTIMONIALS_DATA = {
    title: homepage.testimonialsTitle,
    subtitle: homepage.testimonialsSubtitle,
    logos: (homepage.partners || []).map(({ logo }) => ({
      logoSrc: logo?.sourceUrl,
    })),
    slides: homepage.testimonialsSlideItems,
  };

  const BLOG_DATA = {
    title: homepage.blogTitle,
    subtitle: homepage.blogSubtitle,
    showFilters: true,
  };

  return (
    <Layout
      currentPageClass={'cr-home-page'}
      headerClass={'index-header'}
      mainClass={'index-main'}
      footerClass={'index-footer'}
      seo={seo}
    >
      {(commonData) => (
        <>
          <Hero data={HERO_DATA} />
          <Solution data={SOLUTION_DATA} />
          <Cloud data={CLOUD_DATA} />
          <Features data={FEATURES_DATA} />
          <Testimonials data={TESTIMONIALS_DATA} />
          <Blog data={BLOG_DATA} />
          <Join />
        </>
      )}
    </Layout>
  );
};

export default Home;
