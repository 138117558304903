import React, { useState } from 'react';
import { InfoWindow, Marker } from 'react-google-maps';

import Pin from 'assets/svg/icons/map-pin.svg';

const CustomMarker = ({ marker, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const position = {
    lat: Number(marker.lat),
    lng: Number(marker.lng),
  };

  return (
    <Marker
      position={position}
      icon={Pin}
      {...props}
      onClick={() => setIsOpen(!isOpen)}
    >
      {isOpen && (
        <InfoWindow>
          <div>
            {marker.street}, {marker.zip}, {marker.city}, {marker.country}
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};

export default CustomMarker;
