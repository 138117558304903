import React from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

import Icon from 'components/UI/Icon';
import Modal from 'components/UI/Modal';
import WpImage from 'components/Elements/WPImage';

import useReusableBlocks from 'hooks/graphql/useReusableBlocks';

const EmployeeModal = ({ isOpen, onClose, employee }) => {
  const { forms_ui: data } = useReusableBlocks();

  return (
    <Modal
      label={employee.name}
      isOpen={isOpen}
      onClose={onClose}
      maxWidth={'1200px'}
      className={classnames('cr-modal--dark-btn', styles.modal_cont)}
      overlayClassName={'cr-modal-overlay--as-page-on-mobile'}
    >
      <div className={styles.modal}>
        <div className={styles.modal_person}>
          <WpImage
            src={employee.photo}
            alt={employee.name}
            className={styles.modal_person_img}
          />

          <div className={styles.modal_person_info}>
            <p className={classnames('cr-h2', styles.modal_person_info_title)}>
              {employee.name}
            </p>
            <p
              className={classnames(
                'cr-white cr-lead',
                styles.modal_person_info_pos
              )}
            >
              {employee.position}
            </p>
          </div>
        </div>

        <div className={styles.modal_txt}>
          <div className={styles.modal_txt_inner}>
            <div className={styles.modal_txt_blocks}>
              {employee.description.map(({ title, content }, i) => (
                <div className={styles.modal_txt_block} key={i}>
                  <p
                    className={classnames(
                      'cr-text-3 cr-subheader',
                      styles.modal_txt_block_title
                    )}
                  >
                    {title}
                  </p>
                  <div
                    className={classnames('cr-lead')}
                    dangerouslySetInnerHTML={{ __html: content }}
                  ></div>
                </div>
              ))}
            </div>

            <a
              href={employee.socLinkUrl}
              target="_blank"
              rel="noreferrer"
              className="cr-btn cr-btn--white cr-btn--connect cr-btn--connect-blue cr-btn--small"
            >
              <Icon name="linkedIn" size="16px" />
              <span>{data.buttons.connect}</span>
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EmployeeModal;
