import React from 'react';
import classnames from 'classnames';

import useIntersection from 'hooks/useIntersection';

import * as styles from './header.module.scss';

const Header = ({ title, subtitle }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection();

  return (
    <div className={classnames(styles.header)} ref={scrollWrapper}>
      <div
        className={classnames(styles.inner, { [styles.jsAnimInit]: inViewSec })}
        ref={inViewRefSec}
      >
        <p className={classnames(styles.subtitle, 'cr-subheader cr-text-3')}>
          {subtitle}
        </p>
        <h2 className={classnames('cr-h2')}>{title}</h2>
      </div>
    </div>
  );
};

export default Header;
