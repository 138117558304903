import React from 'react';
import * as styles from './styles.module.scss';
import NewsCard from 'components/Elements/NewsCard';

const NewsList = ({ news }) => (
  <ul className={styles.list}>
    {news.map((news) => (
      <li key={news.id}>
        <NewsCard news={news} />
      </li>
    ))}
  </ul>
);

export default NewsList;
