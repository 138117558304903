export const parseServiceBenefitsTable = (table) =>
  (table || []).map((item) => ({
    iconSrc: item.tdIcon?.sourceUrl,
    name: item.tdIconText,
    traditional: item.tdTraditional,
    cirrus: `<span class="cr-green">${item.tdCirrusHighlighted}</span> ${item.tdCirrus}`,
  }));

export const parseServicePartnersBtns = (btns) =>
  (btns || []).map(({ partnersInfoButtonText, partnersInfoButtonsLink }) => ({
    txt: partnersInfoButtonText,
    to: partnersInfoButtonsLink,
  }));

export const parseServicePartnersList = (list) =>
  (list || []).map(
    (
      {
        partnerImage,
        partnerPosition,
        partnerName,
        partnerInfoComment,
        partnerBenefitItems,
        partnerLogos,
      },
      index
    ) => ({
      id: index + 1,
      logo: partnerLogos?.sourceUrl,
      person: {
        avatar: partnerImage?.sourceUrl,
        name: partnerName,
        position: partnerPosition,
      },
      comment: partnerInfoComment,
      benefits: (partnerBenefitItems || []).map(
        ({ benefitType, benefitDescription }) => ({
          type: benefitType,
          text: benefitDescription,
        })
      ),
    })
  );

export const parseInfoGraphicList = (list) =>
  (list || []).map(
    ({ highlightedText, nonhighlightedText }) =>
      `<span class="cr-txt-medium">${highlightedText}</span> ${nonhighlightedText}`
  );

export const parseInfoGraphicBtn = ({ buttonLink, buttonText }) => ({
  text: buttonText,
  to: buttonLink || '#',
});
