import React from 'react';
import classnames from 'classnames';

import TilesList from './TilesList';
import Testimonial from './Testimonial';
import Button from 'components/UI/Button';
import Icon from 'components/UI/Icon';

import useIntersection from 'hooks/useIntersection';
import useGetCirrusoftModal from 'hooks/useGetCirrusoftModal';
import * as styles from './itemSection.module.scss';

const ItemSection = ({ data, className, id }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.5,
  });
  const { openGetCirrusModal } = useGetCirrusoftModal();

  return (
    <section
      className={classnames(styles.section, className)}
      id={id}
      ref={scrollWrapper}
    >
      <div className="cr-wrap">
        <div className={classnames(styles.inner)}>
          <div
            className={classnames(styles.header, {
              [styles.jsAnimInit]: inViewSec,
            })}
            ref={inViewRefSec}
          >
            <h2
              className={classnames(
                styles.header_title,
                'cr-h2 cr-underline-gradient'
              )}
            >
              {data.title}
            </h2>

            <p className={classnames(styles.header_description, 'cr-lead')}>
              {data.desc}
            </p>
          </div>

          <div className={classnames(styles.flexContainer)}>
            <div className={classnames(styles.flexContainer_list)}>
              <TilesList data={data.list} />
            </div>

            <div className={classnames(styles.flexContainer_bloquote)}>
              <Testimonial data={data.testimonial} />
            </div>
          </div>

          <p className={classnames(styles.linkWrap)}>
            <Button
              className={classnames(
                'cr-btn--large cr-btn--icon cr-btn--icon-red-txt'
              )}
              onClick={openGetCirrusModal}
            >
              {data.button.text}
              <Icon name="corner-arrow-small-bold" />
            </Button>
          </p>
        </div>
      </div>
    </section>
  );
};

export default ItemSection;
