import React from 'react';
import Layout from 'sections/Layout';
import Hero from 'sections/pages/About/Hero';
import Glance from 'sections/pages/About/Glance';
import Banner from 'sections/common/Banner';
import Benefits from 'sections/pages/About/Benefits';
import Business from 'sections/pages/About/Business';
import Research from 'sections/pages/About/Research';
import Industry from 'sections/pages/About/Industry';
import Leadership from 'sections/pages/About/Leadership';
import Careers from 'sections/pages/About/Careers';
import Connect from 'sections/common/Connect';
import Blog from 'components/Blog';

const AboutUs = ({ page }) => {
  const {
    template: { about_us_fields: about },
    seo,
    blogCategories,
  } = page;

  const HERO_DATA = {
    subtitle: about.heroSubtitle,
    title: about.heroTitle,
    titleHighlighted: about.heroTitleHighlighted,
    description: about.heroDescription,
  };

  const GLANCE_DATA = {
    subtitle: about.glanceSubtitle,
    title: about.glanceTitle,
    statistics: (about.glanceStatistics || []).map(
      ({ title, description }) => ({
        number: title,
        desc: description,
      })
    ),
  };

  const BANNER_DATA = {
    image: about.bannerImage,
    description: about.bannerDescription,
  };

  const BENEFITS_DATA = about.benefits;

  const BUSINESS_DATA = {
    header: {
      title: about.businessTitle,
      description: about.businessDescription,
    },
    swiper: (about.businessSwiper || []).map(
      ({ year, description, logo, image }) => ({
        title: year,
        description,
        background: image,
        logo,
      })
    ),
    logos: (about.partners || []).map(({ logo }) => ({
      logoSrc: logo?.sourceUrl,
    })),
  };

  const RESEARCH_DATA = {
    subtitle: about.researchSubtitle,
    title: about.researchTitle,
    description: about.researchDescription,
    point_a: {
      title: about.researchMapPoinATitle,
      subtitle: about.researchMapPoinASubtitle,
      list: about.researchMapPoinAList,
    },
    point_b: {
      title: about.researchMapPoinBTitle,
      subtitle: about.researchMapPoinBSubtitle,
      list: about.researchMapPoinBList,
    },
  };

  const INDUSTRY_DATA = {
    subtitle: about.researchSubtitle,
    title: about.industryTitle,
    title_markered: about.industryTitleHighlighted,
    description: about.industryDescription,
    picture: about.industryImage,
    list: about.industryLogosList,
  };

  const LEADERSHIP_DATA = {
    subtitle: about.leadershipSubtitle,
    title: about.leadershipTitle,
    description: about.leadershipDescription,
    swiper: about.leadershipSwiper,
    button: {
      text: about.leadershipButtonText,
      url: about.leadershipButtonUrl.url,
    },
  };

  const CAREERS_DATA = {
    subtitle: about.careersSubtitle,
    title: about.careersTitle,
    description: about.careersDescription,
    image: about.careersImage,
    button: {
      text: about.careersButtonText,
      url: about.careersButtonUrl.url,
    },
  };

  return (
    <Layout
      currentPageClass={'cr-about-page'}
      headerClass={'cr-about-header'}
      mainClass={'cr-about-main'}
      footerClass={'cr-about-footer'}
      seo={seo}
    >
      {(commonData) => (
        <>
          <Hero data={HERO_DATA} />
          <Glance data={GLANCE_DATA} />
          <Banner data={BANNER_DATA} />
          <Benefits data={BENEFITS_DATA} />
          <Business data={BUSINESS_DATA} />
          <Research data={RESEARCH_DATA} />
          <Industry data={INDUSTRY_DATA} />
          <Leadership data={LEADERSHIP_DATA} />
          <Careers data={CAREERS_DATA} />
          <Connect />
          <Blog data={blogCategories} />
        </>
      )}
    </Layout>
  );
};

export default AboutUs;
