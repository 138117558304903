import React from 'react';
import classnames from 'classnames';
import Item from '../ItemTilesList';

import useIntersection from 'hooks/useIntersection';
import * as styles from './tilesList.module.scss';

const TilesList = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.5,
  });

  return (
    <div ref={scrollWrapper} className={styles.tilesListWrap}>
      <ul
        className={classnames(styles.tilesList, {
          [styles.jsAnimInit]: inViewSec,
        })}
        ref={inViewRefSec}
      >
        {data.map((item, index) => (
          <li key={index}>
            <Item data={item} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TilesList;
