// extracted by mini-css-extract-plugin
export var accordionButton = "accordeon-module--accordionButton--7ssGN";
export var accordion = "accordeon-module--accordion--2JPCh";
export var accordionItem = "accordeon-module--accordionItem--8Fwyb";
export var accordionHeading = "accordeon-module--accordionHeading--emZsR";
export var accordionPanel = "accordeon-module--accordionPanel--3pSt1";
export var accordionButton_title = "accordeon-module--accordionButton_title--xd9AS";
export var panelText = "accordeon-module--panelText--3aGbk";
export var panelText_description = "accordeon-module--panelText_description--1jwY7";
export var panelText_link = "accordeon-module--panelText_link--2XsON";
export var mediaWrap = "accordeon-module--mediaWrap--OIfQA";