const convRetailPage = (WpTag) => `
  heroTitle
  heroTitleHighlighted
  heroSubtitle
  heroList {
    heroListItem
  }
  solutionsTitle
  solutionsSubtitle
  solutionsCardlist {
    itemIcon {
      sourceUrl
    }
    itemText
  }
  infograph1Title
  infograph1Subtitle
  infograph1TextItems {
    highlightedText
    nonhighlightedText
  }
  infograph1Button1 {
    buttonText
  }
  infograph1Button2 {
    buttonLink
    buttonText
  }
  infograph2Title
  infograph2Subtitle
  infograph2TextItems {
    highlightedText
    nonhighlightedText
  }
  infograph2Button1 {
    buttonText
  }
  infograph2Button2 {
    buttonLink
    buttonText
  }
  infograph3Title
  infograph3Subtitle
  infograph3TextItems {
    highlightedText
    nonhighlightedText
  }
  infograph3Button1 {
    buttonText
  }
  infograph3Button2 {
    buttonLink
    buttonText
  }
  transTitle
  transLead
  crossplatTitle
  crossplatLead
  partnersSubtitle
  partnersInfoButtons {
    partnersInfoButtonText
    partnersInfoButtonsLink
  }
  partners {
    partnerLogos {
      sourceUrl
    }
    partnerImage {
      sourceUrl
    }
    partnerName
    partnerPosition
    partnerInfoComment
    partnerBenefitItems {
      benefitType
      benefitDescription
    }
  }
  benefitsTitle
  benefitsTableTh {
    cirrus
    traditional
  }
  benefitsTable {
    tdCirrusHighlighted
    tdCirrus
    tdIcon {
      sourceUrl
    }
    tdIconText
    tdTraditional
  }
  newsTitle
`;

module.exports.convRetailPage = convRetailPage;
