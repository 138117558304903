import React from 'react';

import * as styles from './Address.module.scss';
import Map from '../Map';

const Address = ({ data, ...props }) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.address}>
        <h3 className="cr-h3">{data.locationTitle}</h3>
        <p className="cr-lead cr-text-2">{data.locationSubtitle}</p>
        <div className={styles.address_group}>
          <p className="cr-lead cr-text-2">{data.organization}</p>
          <p className="cr-lead cr-text-2">{data.street}</p>
          <p className="cr-lead cr-text-2">
            {data.zip} {data.city}
          </p>
          <p className="cr-lead cr-text-2">{data.country}</p>
        </div>

        {data.phone && <p className="cr-lead cr-text-2">{data.telephone}</p>}

        <a
          href={`mailto:${data.email}`}
          className="cr-lead cr-main"
          target="_blank"
          rel="noreferrer"
        >
          {data.email}
        </a>
      </div>
      <Map data={data} />
    </div>
  );
};

export default Address;
