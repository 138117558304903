import React, { useState } from 'react';
import classnames from 'classnames';

import * as styles from './style.module.scss';

import { Formik, Form } from 'formik';
import { Link } from 'gatsby';

import Input from 'components/UI/InputFormik';
import Checkbox from 'components/UI/Checkbox';
import Button from 'components/UI/Button';
import Icon from 'components/UI/Icon';
import Spinner from 'components/UI/Spinner';
import validationSchema from './validationSchema';

import useLocale from 'hooks/useLocale';
import useGravityForm from 'hooks/useGravityForm';
import useReusableBlocks from 'hooks/graphql/useReusableBlocks';

const RegistrationForm = ({ onSubmit }) => {
  const submitGravityForm = useGravityForm(1);
  const { forms_ui: data } = useReusableBlocks();
  const [isLoading, setIsLoading] = useState(false);
  const locale = useLocale();

  const {
    forms_ui: {
      buttons: { submit },
    },
  } = useReusableBlocks();

  return (
    <Formik
      initialValues={{
        name: '',
        companyName: '',
        phone: '',
        email: '',
        accept: false,
      }}
      validationSchema={validationSchema(locale.slug)}
      onSubmit={(values) => {
        setIsLoading(true);

        submitGravityForm(values)
          .then(() => {
            onSubmit(values);
          })
          .catch((err) => {
            console.error(err.response);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }}
    >
      <Form noValidate className={styles.form}>
        <ul className={styles.form_inputs}>
          <li>
            <Input
              name="name"
              type="text"
              placeholder={data.placeholders.name || 'Name'}
            />
          </li>
          <li>
            <Input
              name="companyName"
              type="text"
              placeholder={data.placeholders.companyName || 'Company'}
            />
          </li>
          <li>
            <Input
              name="phone"
              type="tel"
              placeholder={data.placeholders.phone || 'Phone'}
            />
          </li>
          <li>
            <Input
              name="email"
              type="email"
              placeholder={data.placeholders.email || 'Email'}
            />
          </li>
        </ul>

        <div className={styles.form_submit}>
          <Checkbox name="accept" className={styles.form_submit_accept}>
            {data.termsAndConditions.text}
            <Link
              to={data.termsAndConditions.to?.uri}
              className={'cr-link cr-main'}
            >
              &nbsp;{data.termsAndConditions.linkText}
            </Link>
          </Checkbox>

          <Button
            type="submit"
            className={classnames(
              'cr-btn--icon cr-btn--large cr-btn--icon-red-bg',
              styles.form_submit_btn
            )}
          >
            {submit}
            <Icon name="corner-arrow-small-bold" />
          </Button>
        </div>

        {isLoading && <Spinner size="80px" absolute />}
      </Form>
    </Formik>
  );
};

export default RegistrationForm;
