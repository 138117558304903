import React, { useMemo } from 'react';
import classnames from 'classnames';

import { getImage, withArtDirection, GatsbyImage } from 'gatsby-plugin-image';

import Item from './Item';

import useIntersection from 'hooks/useIntersection';
import useStaticImage from 'hooks/graphql/useStaticImage';

import * as styles from './hero.module.scss';

const Hero = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.6,
  });
  const getStaticImage = useStaticImage();

  const images = useMemo(
    () =>
      withArtDirection(
        getImage(getStaticImage('Home/hero/bg.jpg').childImageSharp),
        [
          {
            media: '(min-width: 577px) and (max-width: 1200px)',
            image: getImage(
              getStaticImage('Home/hero/bg-tablet.jpg').childImageSharp
            ),
          },
          {
            media: '(max-width: 576px)',
            image: getImage(
              getStaticImage('Home/hero/bg-mobile.jpg').childImageSharp
            ),
          },
        ]
      ),
    [getStaticImage]
  );

  return (
    <section
      className={classnames(styles.section, 'cr-dark-blue-bg')}
      ref={scrollWrapper}
    >
      <div className={styles.section_bg}>
        <GatsbyImage image={images} alt={data.title} />
      </div>

      <div
        className={classnames(styles.inner, 'cr-wrap', {
          [styles.jsAnimInit]: inViewSec,
        })}
      >
        <div className={classnames(styles.header)}>
          <h1 className={classnames(styles.title, 'cr-h1 cr-white')}>
            {data.title}&#160;
            <span
              className={classnames(styles.title_markered, 'cr-main')}
              data-splitting="chars"
            >
              {data.titleHighlighted}
            </span>
          </h1>

          <p
            className={classnames(
              styles.description,
              'cr-lead cr-white cr-opacity-05'
            )}
          >
            {data.description}
          </p>
        </div>

        <div className={classnames(styles.listWrap)} ref={inViewRefSec}>
          <ul className={classnames(styles.list)}>
            {data.tiles.map((tile, i) => (
              <li key={i} className="cr-white">
                <Item tile={tile} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Hero;
