import React from 'react';

import Layout from 'sections/Layout';

import Hero from 'sections/pages/GasStations/Hero';
import Solutions from 'sections/common/Solutions';
import Partners from 'sections/common/Partners';
import Statistic from 'sections/common/Statistic';
import Ecosystem from 'sections/pages/GasStations/Ecosystem';
import Transactions from 'sections/pages/GasStations/Transactions';
import Benefits from 'sections/common/Benefits';
import Questions from 'sections/pages/GasStations/Questions';
import Registration from 'sections/common/Registration';
import Blog from 'components/Blog';

import {
  parseServiceBenefitsTable,
  parseServicePartnersBtns,
  parseServicePartnersList,
} from './parseFuncs';

import 'sections/pages/GasStations/styles.scss';

const GasStations = ({ page }) => {
  const {
    template: { gas_stations_fields: gasPage },
    seo,
    blogCategories,
  } = page;

  const HERO_DATA = {
    title: gasPage.heroTitle,
    titleHighlighted: gasPage.heroTitleHighlighted,
    subtitle: gasPage.heroSubtitle,
    list: (gasPage.heroListItems || []).map(({ heroListText }) => heroListText),
  };

  const SOLUTION_DATA = {
    title: gasPage.solutionsTitle,
    subtitle: gasPage.solutionsSubtitle,
    list: (gasPage.solutionSwipeItems || []).map(
      ({ solutionSwipeText, solutionSwipeIcon }) => ({
        title: solutionSwipeText,
        iconSrc: solutionSwipeIcon?.sourceUrl,
      })
    ),
  };

  const PARTNERS_DATA = {
    partnersSubheader: gasPage.partnersSubheader,
    partnersBtns: parseServicePartnersBtns(gasPage.partnersInfoButtons),
    partnersList: parseServicePartnersList(gasPage.partners),
  };

  const STAT_DATA = {
    list: (gasPage.statItems || []).map(({ statItemNumber, statItemText }) => ({
      number: statItemNumber,
      desc: statItemText,
    })),
  };

  const ECOSYSTEM_DATA = {
    title: gasPage.ecosystemTitle,
    desc: gasPage.ecosystemTextDescription,
    btn: {
      text: gasPage.ecosystemButton[0].buttonText,
    },
    cards: gasPage.ecosystemCards,
  };

  const TRANSACTION_DATA = {
    card: {
      title: gasPage.transTitle,
      desc: gasPage.transactionLead,
    },
  };

  const BENEFITS_DATA = {
    title: gasPage.benefitsTitle,
    benefitsTh: gasPage.benefitsTableTh,
    benefits: parseServiceBenefitsTable(gasPage.benefitsTable),
  };

  const QUESTIONS_DATA = {
    title: gasPage.questionsTitle,
    subtitle: gasPage.questionsSubtitle,
    slides: (gasPage.questionsSwiperSlideItems || []).map(
      ({ slideQuestion, slideTopAnswer }) => ({
        question: slideQuestion,
        answer: slideTopAnswer,
      })
    ),
    popupBtn: {
      title: gasPage.questionsPopupBtn?.title,
      desc: gasPage.questionsPopupBtn?.description,
    },
  };

  return (
    <Layout
      currentPageClass={'cr-gas-stat-page'}
      headerClass={'cr-gas-stat-header'}
      mainClass={'cr-gas-stat-main'}
      footerClass={'cr-gas-stat-footer'}
      seo={seo}
    >
      {(commonData) => (
        <>
          <section className="cr-gas-stat__hero">
            <Hero data={HERO_DATA} />
          </section>

          <section className="cr-wrap cr-gas-stat__solutions">
            <Solutions data={SOLUTION_DATA} />
          </section>

          <section className="cr-wrap cr-gas-stat__partners">
            <Partners data={PARTNERS_DATA} />
          </section>

          <section className="cr-wrap cr-gas-stat__stat">
            <Statistic data={STAT_DATA} />
          </section>

          <section className="cr-gas-stat__ecosystem">
            <Ecosystem data={ECOSYSTEM_DATA} />
          </section>

          <section className="cr-gas-stat__trans">
            <Transactions data={TRANSACTION_DATA} />
          </section>

          <section className="cr-wrap cr-gas-stat__benefits">
            <Benefits data={BENEFITS_DATA} />
          </section>

          <section className="cr-gas-stat__questions">
            <Questions data={QUESTIONS_DATA} />
          </section>

          <section className="cr-wrap cr-gas-stat__registr">
            <Registration />
          </section>

          <Blog data={blogCategories} noMargin={true} />
        </>
      )}
    </Layout>
  );
};

export default GasStations;
