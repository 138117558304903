import React from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';
import Icon from 'components/UI/Icon';
import useReusableBlocks from 'hooks/graphql/useReusableBlocks';
import useSocialData from 'hooks/graphql/useSocials';

import * as styles from './connect.module.scss';

const Connect = () => {
  const socialsData = useSocialData();

  const { becomeFriend: data } = useReusableBlocks();

  const linkedIn = socialsData.find((soc) => soc.name === 'linkedIn');

  return (
    <section className={classnames(styles.section)}>
      <div className="cr-wrap">
        <div className={classnames(styles.inner)}>
          <div className={classnames(styles.content)}>
            <h2 className={classnames(styles.title, 'cr-h2')}>
              {data.title && data.title}
            </h2>
            <p className={classnames('cr-lead')}>
              {data.subtitle && data.subtitle}
            </p>
          </div>

          <Link
            to={linkedIn.url}
            className="cr-btn cr-btn--white cr-btn--connect cr-btn--connect-blue"
          >
            <Icon name="linkedIn" />
            <span>LinkedIn</span>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Connect;
