import React from 'react';
import Layout from 'sections/Layout';

import Terms from 'sections/pages/Terms';

const TermsPage = ({ page }) => {
  const {
    template: { termsConditions: terms },
    seo,
  } = page;

  const TERMS_DATA = {
    title: terms.title,
    subtitle: terms.subtitle,
    content: terms.content,
  };

  return (
    <Layout
      currentPageClass={'cr-terms-page'}
      headerClass={'cr-terms-header'}
      mainClass={'cr-terms-main'}
      footerClass={'cr-terms-footer'}
      seo={seo}
    >
      {(commonData) => (
        <>
          <Terms data={TERMS_DATA} />
        </>
      )}
    </Layout>
  );
};

export default TermsPage;
