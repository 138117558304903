import React, { useMemo } from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import EcosystemLink from './components/EcosystemLink';
import GraphicCard from './components/GraphicCard';

import useIntersection from 'hooks/useIntersection';
import useStaticImage from 'hooks/graphql/useStaticImage';

const Ecosystem = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.6,
  });
  const getStaticImage = useStaticImage();

  const stationImg = useMemo(
    () =>
      getImage(
        getStaticImage('gas-stations/ecosystem/station.png').childImageSharp
      ),
    [getStaticImage]
  );

  const bgImg = useMemo(
    () =>
      getImage(getStaticImage('gas-stations/ecosystem/bg.png').childImageSharp),
    [getStaticImage]
  );

  return (
    <div
      className={classnames(styles.ecosystem, {
        [styles.jsAnimInit]: inViewSec,
      })}
      ref={scrollWrapper}
    >
      <div className={styles.ecosystem_bg}>
        <GatsbyImage image={bgImg} alt="" />
      </div>

      <div className={classnames('cr-wrap', styles.ecosystem_inner)}>
        <div className={styles.ecosystem_inner_l} ref={inViewRefSec}>
          <div className={styles.ecosystem_txt}>
            <h2 className={classnames('cr-h2', styles.ecosystem_txt_title)}>
              {data.title}
            </h2>
            <p className={classnames('cr-lead', styles.ecosystem_txt_desc)}>
              {data.desc}
            </p>

            <EcosystemLink
              data={data.btn}
              className={styles.ecosystem_txt_btn}
            />
          </div>
        </div>
        <div className={styles.ecosystem_inner_r}>
          <div className={styles.ecosystem_graphics}>
            <div className={styles.ecosystem_graphics_station}>
              <GatsbyImage image={stationImg} alt="" />
            </div>
            <div className={styles.ecosystem_graphics_car}></div>

            <GraphicCard
              data={data.cards.integration}
              className={classnames(
                styles.ecosystem_graphics_card,
                styles.ecosystem_graphics_card_integration
              )}
            />

            <GraphicCard
              data={data.cards.hardware}
              className={classnames(
                styles.ecosystem_graphics_card,
                styles.ecosystem_graphics_card_hardware
              )}
            />

            <GraphicCard
              data={data.cards.partnership}
              className={classnames(
                styles.ecosystem_graphics_card,
                styles.ecosystem_graphics_card_partnership
              )}
            />

            <GraphicCard
              data={data.cards.privacy}
              className={classnames(
                styles.ecosystem_graphics_card,
                styles.ecosystem_graphics_card_privacy
              )}
            />

            <GraphicCard
              data={data.cards.protection}
              className={classnames(
                styles.ecosystem_graphics_card,
                styles.ecosystem_graphics_card_protection
              )}
            />

            <GraphicCard
              data={data.cards.standart}
              className={classnames(
                styles.ecosystem_graphics_card,
                styles.ecosystem_graphics_card_standart
              )}
            />

            <GraphicCard
              data={data.cards.reports}
              className={classnames(
                styles.ecosystem_graphics_card,
                styles.ecosystem_graphics_card_reports
              )}
            />

            <GraphicCard
              data={data.cards.setups}
              className={classnames(
                styles.ecosystem_graphics_card,
                styles.ecosystem_graphics_card_setups
              )}
            />

            <GraphicCard
              data={data.cards.modularity}
              className={classnames(
                styles.ecosystem_graphics_card,
                styles.ecosystem_graphics_card_modularity
              )}
            />

            <GraphicCard
              data={data.cards.cloudBased}
              className={classnames(
                styles.ecosystem_graphics_card,
                styles.ecosystem_graphics_card_cloudbased
              )}
            />

            <GraphicCard
              data={data.cards.pricing}
              className={classnames(
                styles.ecosystem_graphics_card,
                styles.ecosystem_graphics_card_pricing
              )}
            />
          </div>

          <EcosystemLink
            data={data.btn}
            className={styles.ecosystem_inner_r_btn}
          />
        </div>
      </div>
    </div>
  );
};

export default Ecosystem;
