// extracted by mini-css-extract-plugin
export var benefits_top = "styles-module--benefits_top--1FELn";
export var jsAnimInit = "styles-module--jsAnimInit--1OtBb";
export var benefits_title = "styles-module--benefits_title--2HUUX";
export var benefits_main = "styles-module--benefits_main--eWKL9";
export var benefits = "styles-module--benefits--2W3QC";
export var table = "styles-module--table--1DRkD";
export var table_tr = "styles-module--table_tr--1M8kO";
export var table_th = "styles-module--table_th--CLzUY";
export var table_td_name = "styles-module--table_td_name--2A8zv";
export var table_td = "styles-module--table_td--hgNkM";
export var table_td_name_icon = "styles-module--table_td_name_icon--ZTKuM";
export var table_td_traditional = "styles-module--table_td_traditional--3wOQB";
export var table_td_cirrus = "styles-module--table_td_cirrus--JyI89";
export var table_grad = "styles-module--table_grad--1APmc";
export var table_grad_traditional = "styles-module--table_grad_traditional--2C2Rx";
export var table_grad_cirrus = "styles-module--table_grad_cirrus--1P4g5";