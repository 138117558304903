import React from 'react';
import CategoryLink from 'components/UI/CategoryLink';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

const CategoryLinksList = ({
  type = 'dark',
  list,
  activeLink,
  title,
  onClick,
}) => (
  <div>
    <p
      className={classnames('cr-subheader cr-text-3', styles.categories_title)}
    >
      {title}
    </p>

    <div className={styles.categories_listwrap}>
      <ul className={styles.categories_list}>
        {list.map((category) => (
          <li key={category.id}>
            <CategoryLink
              type={type}
              isActive={activeLink === category.slug}
              onClick={() => onClick(category.slug)}
            >
              {category.name}
            </CategoryLink>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default CategoryLinksList;
