import React from 'react';
import classnames from 'classnames';

import AdvantagesList from './components/AdvantagesList';
import BenefitsList from './components/BenefitsList';
import ContentFooter from './components/ContentFooter';
import Image from './components/Img';

import useIntersection from 'hooks/useIntersection';

import * as styles from './cloud.module.scss';

const Cloud = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.5,
  });

  return (
    <section
      className={classnames(styles.section)}
      ref={scrollWrapper}
      id="Cloud"
    >
      <div className="cr-wrap">
        <div className={classnames(styles.inner)}>
          <div className={classnames(styles.imgWrap)}>
            <Image />
          </div>

          <div className={classnames(styles.contentWrap)}>
            <div
              className={classnames(styles.content, {
                [styles.jsAnimInit]: inViewSec,
              })}
              ref={inViewRefSec}
            >
              <h2
                className={classnames(
                  styles.title,
                  'cr-h2 cr-underline-gradient cr-underline-gradient--large'
                )}
              >
                <span className={classnames('cr-gradient')}>
                  {data.titleHighlighted}
                </span>
                {data.title}
              </h2>

              <AdvantagesList data={data.advantages} />
              <BenefitsList data={data.benefits} />
            </div>

            <ContentFooter data={data} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cloud;
