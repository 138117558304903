import React from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';
import Icon from 'components/UI/Icon';
import * as styles from './item.module.scss';

const Item = ({ tile }) => {
  return (
    <Link to={tile.heroTileItemLink.uri} className={classnames(styles.tile)}>
      <p className={classnames(styles.inner)}>
        <i className={classnames(styles.iconItem)}>
          <img src={tile.heroTileItemImage?.sourceUrl} alt="" />
        </i>

        <i className={classnames(styles.iconArrow)}>
          <Icon name="corner-arrow-small" />
        </i>
      </p>

      <h3 className={classnames(styles.title, 'cr-h3')}>
        {tile.heroTileItemTitle}
      </h3>

      <p className={classnames(styles.description, 'cr-reg cr-opacity-05')}>
        {tile.heroTileItemDescription}
      </p>
    </Link>
  );
};

export default Item;
