import React from 'react';
import classnames from 'classnames';

import Accordeon from '../Accordeon';

import useIntersection from 'hooks/useIntersection';

import * as styles from './solutionDesktop.module.scss';

const CloudDesktop = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection();

  return (
    <section className={classnames(styles.container)} ref={scrollWrapper}>
      <div className={classnames(styles.inner, 'cr-wrap')}>
        <div className={classnames(styles.accordernControlsWrap)}>
          <p className={classnames(styles.subtitle, 'cr-subheader cr-text-3')}>
            {data.subtitle && data.subtitle}
          </p>
          <h2 className={classnames(styles.title, 'cr-h2')}>{data.title}</h2>
        </div>
        <div className={inViewSec ? styles.jsAnimInit : ''} ref={inViewRefSec}>
          <Accordeon accordeon={data.accordeon} />
        </div>
      </div>
    </section>
  );
};

export default CloudDesktop;
