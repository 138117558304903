import React from 'react';
import classnames from 'classnames';
import Tiles from 'components/Elements/Tiles';
import Hero from './Hero';

import * as styles from './reasons.module.scss';

const Reasons = ({ data }) => {
  return (
    <section className={classnames(styles.section)}>
      <div className="cr-wrap">
        <div className={classnames(styles.inner)}>
          <div className={classnames(styles.inner_hero)}>
            <Hero data={data} />
          </div>
          <div className={classnames(styles.inner_tiles)}>
            <Tiles data={data.list} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reasons;
