import React from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

import { Link } from 'gatsby';

import Button from 'components/UI/Button';
import Icon from 'components/UI/Icon';

import useIntersection from 'hooks/useIntersection';
import useGetCirrusoftModal from 'hooks/useGetCirrusoftModal';

const PartnerInfo = ({ subHeader, btns, comment, benefits }) => {
  const { openGetCirrusModal } = useGetCirrusoftModal();
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.6,
  });

  return (
    <div
      className={classnames(styles.info, { [styles.jsAnimInit]: inViewSec })}
      ref={scrollWrapper}
    >
      <div className={styles.info_top} ref={inViewRefSec}>
        <p
          className={classnames('cr-text-3 cr-subheader', styles.info_suptitle)}
        >
          {subHeader}
        </p>

        <p className={styles.info_comment}>{comment}</p>
      </div>

      <div className={styles.info_center}>
        <ul className={styles.info_benefits}>
          {benefits.map((benefit, i) => (
            <li key={i}>
              <div className={styles.info_benefit}>
                <span className={classnames('cr-h5', styles.info_benefit_type)}>
                  {benefit.type}
                </span>
                <span
                  className={classnames('cr-reg', styles.info_benefit_desc)}
                >
                  {benefit.text}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className={styles.info_bot}>
        <div className={styles.info_btns}>
          {/* TODO: поправить в wp */}
          <Button
            className={classnames(
              'cr-btn--icon cr-btn--large cr-btn--wide cr-btn--icon-red-bg'
            )}
            onClick={openGetCirrusModal}
          >
            {btns[0]?.txt}
            <Icon name="corner-arrow-small-bold" />
          </Button>

          <Link
            to={btns[1]?.to}
            className={classnames(
              'cr-btn cr-btn--icon cr-btn--large cr-btn--wide'
            )}
          >
            {btns[1]?.txt}
            <Icon name="corner-arrow-small-bold" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PartnerInfo;
