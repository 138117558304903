import React from 'react';
import classnames from 'classnames';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import JoinTeam from 'components/Forms/JoinTeam';
import * as styles from './accordion.module.scss';

const AccordionContainer = ({ accordion }) => {
  // TODO: сделать скролл к елементу в таблете и меньше
  // const scrollRef = useRef(null);
  // const toScroll = () => scrollRef.current.scrollIntoView();

  return (
    <Accordion
      className={classnames(styles.accordion)}
      aria-level="4"
      preExpanded="0"
    >
      {accordion?.length &&
        accordion.map((item, index) => {
          return (
            <AccordionItem
              uuid={index.toString()}
              key={index}
              className={classnames(styles.accordionItem)}
            >
              {/* TODO: сделать скролл к елементу в таблете и меньше */}
              {/* <div ref={scrollRef}>
                <button type="button" onClick={toScroll}> */}
              <AccordionItemHeading
                className={classnames(styles.accordionHeading)}
              >
                <AccordionItemButton
                  className={classnames(styles.accordionButton)}
                >
                  <h3 className={classnames('cr-h3')}>{item.area}</h3>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* </button>
              </div> */}

              <AccordionItemPanel className={classnames(styles.accordionPanel)}>
                <JoinTeam data={item} />
              </AccordionItemPanel>
            </AccordionItem>
          );
        })}
    </Accordion>
  );
};

export default AccordionContainer;
