const retailPage = (WpTag) => `
  heroTitle
  heroTitleHighlighted
  heroSubtitle
  heroList {
    heroListItem
  }
  solutionsTitle
  solutionsSubtitle
  solutionsCardlist {
    itemText
    itemIcon {
      sourceUrl
    }
  }
  infograph1Title
  infograph1Subtitle
  infograph1TextItems {
    highlightedText
    nonhighlightedText
  }
  infograph1Button1 {
    buttonLink
    buttonText
  }
  infograph1Button2 {
    buttonLink
    buttonText
  }
  infograph2Title
  infograph2Subtitle
  infograph2TextItems {
    highlightedText
    nonhighlightedText
  }
  infograph2Button1 {
    buttonLink
    buttonText
  }
  infograph2Button2 {
    buttonLink
    buttonText
  }
  infograph3Title
  infograph3Subtitle
  infograph3TextItems {
    highlightedText
    nonhighlightedText
  }
  infograph3Button1 {
    buttonLink
    buttonText
  }
  infograph3Button2 {
    buttonLink
    buttonText
  }
  transTitle
  transLead
  crossplatTitle
  crossplatLead
  benefitsTitle
  benefitsTableTh {
    cirrus
    traditional
  }
  benefitsTable {
    tdCirrus
    tdCirrusHighlighted
    tdIcon {
      sourceUrl
    }
    tdIconText
    tdTraditional
  }
  newsTitle
`;

module.exports.retailPage = retailPage;
