import React, { useMemo } from 'react';
import classnames from 'classnames';

import { getImage, withArtDirection, GatsbyImage } from 'gatsby-plugin-image';

import useStaticImage from 'hooks/graphql/useStaticImage';

import * as styles from './styles.module.scss';

const Crossplatform = ({ data }) => {
  const getStaticImage = useStaticImage();

  const images = useMemo(
    () =>
      withArtDirection(
        getImage(
          getStaticImage('common/crossplatform/img-desktop.png').childImageSharp
        ),
        [
          {
            media: '(min-width: 577px) and (max-width: 1200px)',
            image: getImage(
              getStaticImage('common/crossplatform/img-tablet.png')
                .childImageSharp
            ),
          },
          {
            media: '(max-width: 576px)',
            image: getImage(
              getStaticImage('common/crossplatform/img-mobile.png')
                .childImageSharp
            ),
          },
        ]
      ),
    [getStaticImage]
  );

  return (
    <div className={styles.crossplat}>
      <div className={styles.crossplat_txt}>
        <h2
          className={classnames(
            'cr-underline-gradient cr-h2',
            styles.crossplat_txt_title
          )}
        >
          {data.title}
        </h2>

        <p className={classnames('cr-lead')}>{data.desc}</p>
      </div>

      <div className={styles.crossplat_img}>
        <GatsbyImage image={images} alt={data.title} />
      </div>
    </div>
  );
};

export default Crossplatform;
