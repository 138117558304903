import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper/core';

import WpImage from 'components/Elements/WPImage';

import './styles.scss';

SwiperCore.use([Autoplay]);

const ImgSwiper = ({ data }) => {
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    setSlides(data);
  }, []); // eslint-disable-line

  return (
    <div>
      <div className={classnames('cr-leadership-img-slider')}>
        <Swiper
          freeMode={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            1200: {
              slidesPerView: 3.5,
            },
            768: {
              slidesPerView: 2.75,
            },
            320: {
              slidesPerView: 1.25,
            },
          }}
          centeredSlides={true}
          loop="true"
        >
          {slides.length &&
            slides.map((slide, i) => {
              return (
                <SwiperSlide key={slide.leaderName + Date.now()}>
                  <div className="cr-leadership-img-slider__slide">
                    <WpImage
                      key={slide.leaderName + Date.now()}
                      src={slide.slideImage?.sourceUrl}
                      alt={slide.slideImage?.altText || slide.leaderName}
                    />
                    <div className="cr-slide-descripton">
                      <p className="cr-reg cr-txt-up cr-white">
                        {slide.leaderName}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </div>
  );
};

export default ImgSwiper;
