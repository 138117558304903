import React from 'react';
import classnames from 'classnames';
import useIntersection from 'hooks/useIntersection';
import * as styles from './advantagesList.module.scss';

const AdvantagesList = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.5,
  });

  return (
    <div ref={scrollWrapper}>
      <ul
        className={classnames(styles.advantages, {
          [styles.jsAnimInit]: inViewSec,
        })}
        ref={inViewRefSec}
      >
        {data.map((item) => (
          <li key={item.title}>
            <h3 className={classnames(styles.title, 'cr-h3 cr-gradient')}>
              {item.title}
            </h3>
            <p className={classnames(styles.text, 'cr-reg')}>{item.text}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdvantagesList;
