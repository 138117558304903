import React from 'react';

import Layout from 'sections/Layout';
import Contact from 'sections/pages/Contact';

const PageContact = ({ page }) => {
  const {
    template: { contactus_fields: contactUsPage },
    seo,
  } = page;

  return (
    <Layout
      currentPageClass={'cr-contact-page'}
      headerClass={'cr-contact-header'}
      mainClass={'cr-contact-main'}
      footerClass={'cr-contact-footer'}
      seo={seo}
    >
      {(commonData) => <Contact data={contactUsPage} />}
    </Layout>
  );
};

export default PageContact;
