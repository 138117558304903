import React, { useRef } from 'react';
import classnames from 'classnames';

import WpImage from 'components/Elements/WPImage';
import Content from './Content';
import ScaleOnScroll from '@/helpers/scaleOnScroll';

import useIntersection from 'hooks/useIntersection';

import * as styles from './industry.module.scss';

const Industry = ({ data }) => {
  const scrollWrap = useRef();
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.5,
  });

  return (
    <section className={classnames(styles.section)} ref={scrollWrapper}>
      <div className="cr-wrap">
        <div
          className={classnames(styles.inner, {
            [styles.jsAnimInit]: inViewSec,
          })}
        >
          <div className={classnames(styles.imgWrap)} ref={inViewRefSec}>
            <ScaleOnScroll scrollWrapper={scrollWrap}>
              {(scale) => (
                <div
                  ref={scrollWrap}
                  style={{
                    willChange: 'transform',
                    transform: `scale3d(${scale.x}, ${scale.y}, ${scale.z})`,
                  }}
                >
                  <WpImage
                    src={data.picture.sourceUrl}
                    alt={data.picture.altText}
                  />
                </div>
              )}
            </ScaleOnScroll>
          </div>
          <div className={classnames(styles.contentWrap)}>
            <Content data={data} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Industry;
