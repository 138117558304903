import React, { useState, useCallback } from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

import Employee from './components/Employee';
import BecomeFriend from './components/BecomeFriend';
import EmployeeModal from './components/EmployeeModal';

import useIntersection from 'hooks/useIntersection';
import { DESKTOP_KEY } from 'constants/devices.js';
import useDeviceState from 'hooks/useDeviceState';
import {
  useContextState,
  useContextDispatch,
  ACTIONS_CREATORS,
  MODALS,
} from '@/context';

const Team = ({ data }) => {
  const [curEmployee, setCurEmployee] = useState(null);
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.2,
  });
  const deviceState = useDeviceState();
  const { curModal } = useContextState();
  const dispatch = useContextDispatch();

  const closeModalHandler = useCallback(() => {
    setCurEmployee(null);
    dispatch(ACTIONS_CREATORS.setEmployeeModal(false));
  }, [setCurEmployee, dispatch]);

  const employeeClickHandler = useCallback(
    (employee) => {
      setCurEmployee(employee);
      dispatch(ACTIONS_CREATORS.setEmployeeModal(true));
    },
    [setCurEmployee, dispatch]
  );

  return (
    <div ref={scrollWrapper}>
      <ul
        className={classnames(styles.team_list, {
          [styles.jsAnimInit]: inViewSec && deviceState === DESKTOP_KEY,
        })}
        ref={inViewRefSec}
      >
        {data.list.map((employee, i) => (
          <li key={i}>
            <Employee employee={employee} onClick={employeeClickHandler} />
          </li>
        ))}
        <li className={styles.team_list_friend}>
          <BecomeFriend data={data.becomeFriend} />
        </li>
      </ul>

      {curEmployee && (
        <EmployeeModal
          isOpen={curModal === MODALS.EMPLOYEE}
          onClose={closeModalHandler}
          employee={curEmployee}
        />
      )}
    </div>
  );
};

export default Team;
