import React, { useMemo } from 'react';
import classnames from 'classnames';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import useStaticImage from 'hooks/graphql/useStaticImage';
import useIntersection from 'hooks/useIntersection';

import * as styles from './img.module.scss';

const Img = () => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.3,
  });
  const getStaticImage = useStaticImage();

  const image = useMemo(
    () =>
      getImage(
        getStaticImage('features/cloud/features-cloud.png').childImageSharp
      ),
    [getStaticImage]
  );

  return (
    <div ref={scrollWrapper}>
      <div
        className={classnames(styles.img, { [styles.jsAnimInit]: inViewSec })}
        ref={inViewRefSec}
      >
        <GatsbyImage image={image} alt="" />
      </div>
    </div>
  );
};

export default Img;
