const contactUsPage = (WpTag) => `
  formTopicOptions {
    option
    value
  }
  imageSubtitle
  imageTitle
  location {
    city
    country
    email
    locationSubtitle
    locationTitle
    mapLocation
    organization
    street
    telephone
    zip
  }
  subtitle
  title
  formTitle
  formSubtitle
`;

module.exports.contactUsPage = contactUsPage;
