import React, { useMemo } from 'react';
import classnames from 'classnames';

import { GatsbyImage, withArtDirection, getImage } from 'gatsby-plugin-image';

import useIntersection from 'hooks/useIntersection';
import useStaticImage from 'hooks/graphql/useStaticImage';

import * as styles from './hero.module.scss';
import 'styles/common/_hero.scss';

const Hero = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.6,
  });
  const getStaticImage = useStaticImage();

  const images = useMemo(
    () =>
      withArtDirection(
        getImage(
          getStaticImage('features/hero/bg-desktop.jpg').childImageSharp
        ),
        [
          {
            media: '(min-width: 577px) and (max-width: 1200px)',
            image: getImage(
              getStaticImage('features/hero/bg-tablet.jpg').childImageSharp
            ),
          },
          {
            media: '(max-width: 576px)',
            image: getImage(
              getStaticImage('features/hero/bg-mobile.jpg').childImageSharp
            ),
          },
        ]
      ),
    [getStaticImage]
  );

  return (
    <div ref={scrollWrapper}>
      <section
        className={classnames(styles.section, 'cr-hero-sec ')}
        ref={inViewRefSec}
      >
        <div className="cr-wrap">
          <div
            className={classnames(styles.content, 'cr-content', {
              jsAnimInit: inViewSec,
            })}
          >
            <p
              className={classnames(
                'cr-page-name cr-subheader cr-text-3 cr-opacity-05'
              )}
            >
              {data.subheader}
            </p>

            <h1 className={classnames(styles.title, 'cr-h1 cr-white')}>
              {data.title} <br />
              <span className={classnames('cr-title-extra')}>
                {data.titleHighlighted}
              </span>
            </h1>

            <p
              className={classnames(
                styles.description,
                'cr-description cr-lead cr-white'
              )}
            >
              {data.description}
            </p>
          </div>
        </div>

        <div className={classnames(styles.mediaWrap)}>
          <GatsbyImage image={images} alt={data.title} />
        </div>
      </section>
    </div>
  );
};

export default Hero;
