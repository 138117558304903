import React from 'react';
import classnames from 'classnames';
import useIntersection from 'hooks/useIntersection';
import * as styles from './styles.module.scss';

const Statistics = ({ list }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.6,
  });

  return (
    <div className={classnames(styles.stat)} ref={scrollWrapper}>
      <ul
        className={classnames(styles.stat_list, inViewSec && styles.jsAnimInit)}
        ref={inViewRefSec}
      >
        {list.map((item, i) => (
          <li key={i}>
            <div>
              <span
                className={classnames('cr-gradient', styles.statitem_number)}
              >
                {item.number}
              </span>
              <p className={classnames('cr-lead')}>{item.desc}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Statistics;
