import React, { useCallback } from 'react';
import classnames from 'classnames';

import DropZone from 'components/UI/DropZone';
import Icon from 'components/UI/Icon';

import { DESKTOP_KEY } from 'constants/devices.js';
import useDeviceState from 'hooks/useDeviceState';
import * as styles from './dropDefault.module.scss';

import useReusableBlocks from 'hooks/graphql/useReusableBlocks';

const DropDefault = ({ onUpload, name }) => {
  const deviceState = useDeviceState();

  const {
    forms_ui: {
      placeholders: { dragUpload, submitYourCv },
    },
  } = useReusableBlocks();

  const onFileUpload = useCallback(
    (files) => {
      if (files.length === 0) return;

      onUpload(files[0]);
    },
    [onUpload]
  );

  return (
    <DropZone
      name={name}
      onFileUpload={onFileUpload}
      inputText={dragUpload.clickUpload}
    >
      <i className={classnames(styles.icon)}>
        <Icon name="drop-file" />
      </i>

      <h4 className={classnames(styles.title, 'cr-h4')}>{submitYourCv}</h4>

      {deviceState === DESKTOP_KEY && (
        <span className={classnames('cr-reg')}>{dragUpload.dragBox}</span>
      )}
    </DropZone>
  );
};

export default DropDefault;
