import React from 'react';
import classnames from 'classnames';
import Item from './Item';
import useIntersection from 'hooks/useIntersection';
import * as styles from './tiles.module.scss';

const Tiles = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.3,
  });

  return (
    <div ref={scrollWrapper}>
      <ul
        className={classnames(styles.list, inViewSec && styles.jsAnimInit)}
        ref={inViewRefSec}
      >
        {data.length &&
          data.map((item, i) => {
            return (
              <li key={i}>
                <Item data={item} />
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default Tiles;
