import React from 'react';
import classnames from 'classnames';
import useIntersection from 'hooks/useIntersection';
import * as styles from './runDevice.module.scss';

import RunDeviceImg from '@/images/features/run-device/device-desktop.png';

const RunDevice = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection();

  return (
    <section className={classnames(styles.section)} ref={scrollWrapper}>
      <div className="cr-wrap">
        <div
          className={classnames(styles.inner, {
            [styles.jsAnimInit]: inViewSec,
          })}
          ref={inViewRefSec}
        >
          <div className={classnames(styles.content)}>
            <h2
              className={classnames(
                styles.title,
                'cr-h2 cr-underline-gradient'
              )}
            >
              {data.title}
            </h2>
            <p className={classnames(styles.description, 'cr-lead')}>
              {data.desc}
            </p>
          </div>
          <picture className={classnames(styles.imgWrap)}>
            <img src={RunDeviceImg} alt="" />
          </picture>
        </div>
      </div>
    </section>
  );
};

export default RunDevice;
