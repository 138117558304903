import { useStaticQuery, graphql } from 'gatsby';
import useLocale from 'hooks/useLocale';

const query = graphql`
  query tagsQuery {
    allWpTag {
      nodes {
        slug
        name
        id
        description
        count
        language {
          code
        }
      }
    }
  }
`;

const useTags = () => {
  const currentLocale = useLocale();

  const {
    allWpTag: { nodes },
  } = useStaticQuery(query);

  return nodes
    .filter(({ language }) => language.code === currentLocale.code)
    .sort((a, b) => b.count - a.count);
};

export default useTags;
