import React from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

const CategoryLink = ({
  type = 'dark',
  isActive = false,
  children,
  onClick = () => {},
}) => (
  <button
    type="button"
    className={classnames(styles.category, styles[type], {
      [styles.active]: isActive,
    })}
    onClick={onClick}
  >
    <span className={classnames('cr-h5', styles.category_text)}>
      {children}
    </span>
  </button>
);

export default CategoryLink;
