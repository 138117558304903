import React from 'react';
import classnames from 'classnames';
import useIntersection from 'hooks/useIntersection';
import * as styles from './banner.module.scss';

import WpImage from 'components/Elements/WPImage';

const Banner = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.6,
  });

  return (
    <section
      className={classnames(styles.section, { [styles.jsAnimInit]: inViewSec })}
      ref={scrollWrapper}
    >
      <div className={classnames(styles.inner, 'cr-wrap')} ref={inViewRefSec}>
        <p className={classnames(styles.descripton, 'cr-reg cr-white')}>
          {data.description}
        </p>
      </div>
      {data.image && (
        <WpImage
          src={data.image.sourceUrl}
          alt={data.image.altText}
          className={classnames(styles.image)}
        />
      )}
    </section>
  );
};

export default Banner;
