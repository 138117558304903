import React from 'react';
import classnames from 'classnames';
import useIntersection from 'hooks/useIntersection';
import * as styles from './styles.module.scss';

import Card from '../Card';

const DesktopList = ({ list }) => {
  const { inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.6,
  });

  return (
    <ul
      className={classnames(styles.list, inViewSec && styles.jsAnimInit)}
      ref={inViewRefSec}
      // refs={scrollWrapper}
    >
      {list.map((item, i) => (
        <li key={i}>
          <Card card={item} />
        </li>
      ))}
    </ul>
  );
};

export default DesktopList;
