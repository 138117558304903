import React from 'react';
import { Formik, Form } from 'formik';
import { Link } from 'gatsby';
import classnames from 'classnames';

import * as styles from './style.module.scss';
import validationSchema from './validationSchema';
import Input from 'components/UI/InputFormik';
import Checkbox from 'components/UI/Checkbox';

// TODO: привести к одному компоненту
import DropdownForm from 'components/UI/DropdownForm';
import Button from 'components/UI/Button';
import Icon from 'components/UI/Icon';

import useLocale from 'hooks/useLocale';

const FormElem = ({ placeholders, options, onSubmit }) => {
  const locale = useLocale();

  return (
    <Formik
      initialValues={{
        topic: '',
        name: '',
        companyName: '',
        phone: '',
        email: '',
        accept: false,
      }}
      validationSchema={validationSchema(locale.slug)}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setSubmitting(false);
          onSubmit(values);
        }, 400);
      }}
    >
      {({ values, setFieldValue, errors }) => {
        return (
          <Form className={styles.form} noValidate>
            <ul className={styles.form_inputs}>
              <li>
                <DropdownForm
                  list={options}
                  name="topic"
                  placeholder={placeholders.topic}
                  error={errors.topic}
                  onSelect={(data) => setFieldValue('topic', data.value)}
                />
              </li>
              <li>
                <Input
                  name="name"
                  type="text"
                  placeholder={placeholders.name}
                />
              </li>
              <li>
                <Input
                  name="companyName"
                  type="text"
                  placeholder={placeholders.companyName}
                />
              </li>
              <li>
                <Input
                  name="phone"
                  type="tel"
                  placeholder={placeholders.phone}
                />
              </li>
              <li>
                <Input
                  name="email"
                  type="email"
                  placeholder={placeholders.email}
                />
              </li>
            </ul>

            <div className={styles.form_submit}>
              <Checkbox name="accept">
                {placeholders.termsConditions.text}&nbsp;
                <Link
                  to={placeholders.termsConditions?.to.uri || '#'}
                  className={'cr-link cr-main'}
                >
                  {placeholders.termsConditions.linkText}
                </Link>
              </Checkbox>
            </div>

            <Button
              type="submit"
              className={classnames(
                'cr-btn--icon cr-btn--large cr-btn--icon-red-bg',
                styles.form_submit_btn
              )}
            >
              {placeholders.submitButton}
              <Icon name="corner-arrow-small-bold" />
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormElem;
