import React from 'react';
import classnames from 'classnames';

import useIntersection from 'hooks/useIntersection';
import * as styles from './media.module.scss';

const Media = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.9,
  });

  return (
    <section className={classnames(styles.section)} ref={scrollWrapper}>
      <div className={classnames(styles.inner, inViewSec && styles.jsAnimInit)}>
        <div className={classnames(styles.device)}>
          <div className={classnames(styles.device_media)}>
            {/* TODO: временно, пока что используется картинка со стилей bg-image */}
            {/* {data.video ? (
              <video
                src={data.video}
                poster={data.poster}
                className={styles.video}
              ></video>
            ) : (
              <img src={data.poster} className={styles.video} alt="" />
            )} */}
          </div>
        </div>

        <div className="cr-wrap" ref={inViewRefSec}>
          <ul className={classnames(styles.list)}>
            {data.sloganList.map((slogan) => (
              <li key={slogan}>
                <p className={classnames(styles.list_slogan)}>
                  <span>{slogan}</span>
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Media;
