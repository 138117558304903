import React from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

import useIntersection from 'hooks/useIntersection';

const Benefits = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.6,
  });

  return (
    <div
      ref={scrollWrapper}
      className={classnames(styles.benefits, inViewSec && styles.jsAnimInit)}
    >
      <div className={styles.benefits_top}>
        <h2 className={classnames('cr-h2', styles.benefits_title)}>
          {data.title}
        </h2>
      </div>

      <div className={styles.benefits_main} ref={inViewRefSec}>
        <ul className={classnames('cr-reg', styles.table)}>
          <span
            className={classnames(
              styles.table_grad,
              styles.table_grad_traditional
            )}
          ></span>
          <span
            className={classnames(styles.table_grad, styles.table_grad_cirrus)}
          ></span>

          <li className={styles.table_tr}>
            <span
              className={classnames(
                styles.table_td,
                styles.table_th,
                styles.table_td_name
              )}
            ></span>
            <span
              className={classnames(
                'cr-lead',
                styles.table_td,
                styles.table_th,
                styles.table_td_traditional
              )}
            >
              {data.benefitsTh?.traditional}
            </span>
            <span
              className={classnames(
                'cr-lead',
                styles.table_td,
                styles.table_th,
                styles.table_td_cirrus
              )}
            >
              {data.benefitsTh?.cirrus}
            </span>
          </li>

          {data.benefits.map((row, i) => (
            <li key={i} className={styles.table_tr}>
              <span
                className={classnames(styles.table_td, styles.table_td_name)}
              >
                {row.iconSrc && (
                  <i
                    className={classnames(
                      'cr-text-1',
                      styles.table_td_name_icon
                    )}
                  >
                    <img src={row.iconSrc} alt="" />
                  </i>
                )}

                <span className={classnames('cr-txt-medium')}>{row.name}</span>
              </span>
              <span
                className={classnames(
                  styles.table_td,
                  styles.table_td_traditional
                )}
              >
                <span
                  dangerouslySetInnerHTML={{ __html: row.traditional }}
                ></span>
              </span>
              <span
                className={classnames(styles.table_td, styles.table_td_cirrus)}
              >
                <span dangerouslySetInnerHTML={{ __html: row.cirrus }}></span>
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Benefits;
