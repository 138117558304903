import React, { useRef, useEffect } from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Navigation, Pagination } from 'swiper';

import SliderBtn from 'components/UI/SliderBtn';
import SwiperPagin from 'components/UI/SwiperPagin';
import Slide from './components/Slide';

SwiperCore.use(Navigation, Pagination);

const SwiperContainer = ({ testimonials }) => {
  const containerRef = useRef(null);
  const prevBtnRef = useRef(null);
  const nextBtnRef = useRef(null);
  const paginRef = useRef(null);

  useEffect(() => {
    const swiper = new Swiper(containerRef.current, {
      slidesPerView: 1,
      spaceBetween: 20,
      loop: true,
      pagination: {
        el: paginRef.current,
      },
      navigation: {
        nextEl: nextBtnRef.current,
        prevEl: prevBtnRef.current,
      },
    });

    return () => {
      swiper.destroy(true, true);
    };
  }, []);

  return (
    <div className={styles.swiper}>
      <div
        ref={containerRef}
        className={classnames('swiper-container', styles.swiper_cont)}
      >
        <div className={classnames('swiper-wrapper')}>
          {testimonials.map((slide, i) => (
            <div key={i} className={classnames('swiper-slide')}>
              <Slide data={slide} />
            </div>
          ))}
        </div>
      </div>

      <div className={styles.swiper_cntrls}>
        <SliderBtn
          ref={prevBtnRef}
          className={styles.swiper_btn}
          type="left"
          mod="white"
        />

        <SliderBtn
          ref={nextBtnRef}
          className={styles.swiper_btn}
          type="right"
          mod="white"
        />

        <SwiperPagin ref={paginRef} className={styles.swiper_pagin} />
      </div>
    </div>
  );
};

export default SwiperContainer;
