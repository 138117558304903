import React from 'react';
import { getImage } from 'gatsby-plugin-image';

import Layout from '@/sections/Layout';
import Hero from '@/sections/pages/Hospitality/Hero';
import Solutions from '@/sections/common/Solutions';
import InfoGraphics from '@/sections/common/ServiceInfoGraphics';
import Transactions from '@/sections/pages/Hospitality/Transactions';
import Crossplatform from '@/sections/common/Crossplatform';
import Benefits from '@/sections/common/Benefits';
import Registration from '@/sections/common/Registration';
import Blog from 'components/Blog';

import useStaticImage from 'hooks/graphql/useStaticImage';

import {
  parseServiceBenefitsTable,
  parseInfoGraphicList,
  parseInfoGraphicBtn,
} from './parseFuncs';

import '@/sections/pages/Hospitality/styles.scss';

const Hospitality = ({ page }) => {
  const getStaticImage = useStaticImage();

  const {
    template: { hospitality_fields: hospitalityPage },
    seo,
    blogCategories,
  } = page;

  const HERO_DATA = {
    title: hospitalityPage.heroTitle,
    titleHighlighted: hospitalityPage.heroTitleHighlighted,
    subtitle: hospitalityPage.heroSubtitle,
    list: (hospitalityPage.heroListItems || []).map(
      ({ heroListText }) => heroListText
    ),
  };

  const SOLUTION_DATA = {
    title: hospitalityPage.solutionsTitle,
    subtitle: hospitalityPage.solutionsSubtitle,
    list: (hospitalityPage.solutionSwipeItems || []).map(
      ({ solutionSwipeText, solutionSwipeIcon }) => ({
        title: solutionSwipeText,
        iconSrc: solutionSwipeIcon?.sourceUrl,
      })
    ),
  };

  const INFO_GRAPHICS_DATA_1 = {
    title: hospitalityPage.infograph1Title,
    suptitle: hospitalityPage.infograph1Subtitle,
    mediaSrc: getImage(
      getStaticImage('hospitality/info-graphics/img1.png').childImageSharp
    ),
    list: parseInfoGraphicList(hospitalityPage.infograph1TextItems),
    btn1: parseInfoGraphicBtn(hospitalityPage.infograph1Button1),
    btn2: parseInfoGraphicBtn(hospitalityPage.infograph1Button2),
  };

  const INFO_GRAPHICS_DATA_2 = {
    title: hospitalityPage.infograph2Title,
    suptitle: hospitalityPage.infograph2Subtitle,
    mediaSrc: getImage(
      getStaticImage('hospitality/info-graphics/img2.png').childImageSharp
    ),
    list: parseInfoGraphicList(hospitalityPage.infograph2TextItems),
    btn1: parseInfoGraphicBtn(hospitalityPage.infograph2Button1),
    btn2: parseInfoGraphicBtn(hospitalityPage.infograph2Button2),
    isReversed: true,
  };

  const INFO_GRAPHICS_DATA_3 = {
    title: hospitalityPage.infograph3Title,
    suptitle: hospitalityPage.infograph3Subtitle,
    mediaSrc: getImage(
      getStaticImage('hospitality/info-graphics/img3.png').childImageSharp
    ),
    list: parseInfoGraphicList(hospitalityPage.infograph3TextItems),
    btn1: parseInfoGraphicBtn(hospitalityPage.infograph3Button1),
    btn2: parseInfoGraphicBtn(hospitalityPage.infograph3Button2),
  };

  const TRANSACTION_DATA = {
    card: {
      title: hospitalityPage.transTitle,
      desc: hospitalityPage.transLead,
    },
  };

  const CROSSPLATFORM_DATA = {
    title: hospitalityPage.crossplatTitle,
    desc: hospitalityPage.crossplatLead,
  };

  const BENEFITS_DATA = {
    title: hospitalityPage.benefitsTitle,
    benefitsTh: hospitalityPage.benefitsTableTh,
    benefits: parseServiceBenefitsTable(hospitalityPage.benefitsTable),
  };

  return (
    <Layout
      currentPageClass={'cr-hospitality-page'}
      headerClass={'cr-hospitality-header'}
      mainClass={'cr-hospitality-main'}
      footerClass={'cr-hospitality-footer'}
      seo={seo}
    >
      {(commonData) => (
        <>
          <section className="cr-hospitality__hero">
            <Hero data={HERO_DATA} />
          </section>

          <section className="cr-wrap cr-hospitality__solutions">
            <Solutions data={SOLUTION_DATA} />
          </section>

          <section className="cr-wrap cr-conv-retail__info-grap">
            <InfoGraphics data={INFO_GRAPHICS_DATA_1} />
          </section>

          <section className="cr-wrap cr-conv-retail__info-grap">
            <InfoGraphics data={INFO_GRAPHICS_DATA_2} />
          </section>

          <section className="cr-wrap cr-conv-retail__info-grap">
            <InfoGraphics data={INFO_GRAPHICS_DATA_3} />
          </section>

          <section className="cr-hospitality__trans">
            <Transactions data={TRANSACTION_DATA} />
          </section>

          <section className="cr-wrap cr-hospitality__cross-plat">
            <Crossplatform data={CROSSPLATFORM_DATA} />
          </section>

          <section className="cr-wrap cr-hospitality__benefits">
            <Benefits data={BENEFITS_DATA} />
          </section>

          <section className="cr-wrap cr-hospitality__registr">
            <Registration />
          </section>

          <Blog data={blogCategories} noMargin={true} />
        </>
      )}
    </Layout>
  );
};

export default Hospitality;
