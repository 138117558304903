import React, { useMemo } from 'react';
import classnames from 'classnames';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import useStaticImage from 'hooks/graphql/useStaticImage';
import useIntersection from 'hooks/useIntersection';

import * as styles from './hero.module.scss';
import 'styles/common/_hero.scss';

const Hero = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.6,
  });
  const getStaticImage = useStaticImage();

  const image = useMemo(
    () =>
      getImage(
        getStaticImage('careers/hero/careers-bg-original.jpg').childImageSharp
      ),
    [getStaticImage]
  );

  return (
    <div ref={scrollWrapper}>
      <section
        className={classnames(styles.section, 'cr-hero-sec')}
        ref={inViewRefSec}
      >
        <div className={classnames(styles.inner, 'cr-wrap')}>
          <div
            className={classnames(styles.content, 'cr-content', {
              jsAnimInit: inViewSec,
            })}
          >
            <p
              className={classnames(
                styles.pageName,
                'cr-page-name cr-subheader cr-text-3 cr-opacity-05'
              )}
            >
              {data.subtitle}
            </p>

            <h1 className={classnames(styles.title, 'cr-h1 cr-gradient')}>
              {data.title}
            </h1>

            <p className={classnames('cr-description cr-lead cr-white')}>
              {data.description}
            </p>
          </div>
        </div>

        <div>
          <GatsbyImage image={image} alt={data.title} />
        </div>
      </section>
    </div>
  );
};

export default Hero;
