import React from 'react';
import classnames from 'classnames';
import useIntersection from 'hooks/useIntersection';
import * as styles from './benefitsList.module.scss';

const BenefitsList = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.5,
  });

  return (
    <div ref={scrollWrapper}>
      <ul
        className={classnames(
          styles.benefits,
          'cr-checked-list cr-checked-list--large',
          { [styles.jsAnimInit]: inViewSec }
        )}
        ref={inViewRefSec}
      >
        {data.map((item) => (
          <li key={item.title}>
            <h5 className={classnames(styles.title, 'cr-h5')}>{item.title}</h5>
            <p className={classnames(styles.text, 'cr-reg')}>{item.text}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BenefitsList;
