import React, { forwardRef } from 'react';
import classnames from 'classnames';
import './styles.scss';

const SwiperPagin = ({ className = '' }, ref) => (
  <div
    ref={ref}
    className={classnames(className, 'swiper-pagination-bullets')}
  ></div>
);

export default forwardRef(SwiperPagin);
