import React from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

const Testimonials = ({ data }) => (
  <div>
    <p className={classnames('cr-lead', styles.slide_text)}>
      {data.slideItemText}
    </p>

    <p className={styles.slide_info}>
      <span className={classnames('cr-h5 cr-main', styles.slide_info_author)}>
        {data.slideItemInfoAuthor}
      </span>
      <span className={classnames('cr-reg cr-text-2 cr-opacity-05')}>
        {data.slideItemInfoInfo}
      </span>
    </p>
  </div>
);

export default Testimonials;
