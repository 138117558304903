import React, { useState, useCallback } from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

import RegistrationForm from 'components/Forms/Registration';
import RegistrationFormSuccess from 'components/Forms/Registration/components/Success';

import useReusableBlocks from 'hooks/graphql/useReusableBlocks';

const Registration = () => {
  const { registerForm: data } = useReusableBlocks();
  const [isSuccess, setIsSuccess] = useState(false);

  const onSubmitHandler = useCallback(() => {
    setIsSuccess(true);
  }, [setIsSuccess]);

  return (
    <div className={styles.registr}>
      <div className={styles.registr_txt}>
        <h2 className={classnames('cr-h2', styles.registr_txt_title)}>
          {data.registerTitle}
        </h2>
        <p className={classnames('cr-lead')}>{data.registerSubtitle}</p>
      </div>
      <div className={styles.registr_form}>
        {isSuccess ? (
          <RegistrationFormSuccess />
        ) : (
          <RegistrationForm onSubmit={onSubmitHandler} />
        )}
      </div>
    </div>
  );
};

export default Registration;
