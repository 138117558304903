import React, { useCallback } from 'react';
import { FileDrop } from 'react-file-drop';
import './styles.scss';

const DropZone = ({
  onFileUpload,
  children,
  inputText,
  name = 'file',
  className,
}) => {
  const onFileInputChange = useCallback(
    ({ target }) => {
      const { files } = target;

      onFileUpload(files);
    },
    [onFileUpload]
  );

  return (
    <FileDrop onDrop={onFileUpload}>
      <div className={className}>
        {children}
        <label>
          {inputText && <span className="text">&#160; {inputText}</span>}

          <input
            onChange={onFileInputChange}
            name={name}
            type="file"
            className="hidden"
          />
        </label>
      </div>
    </FileDrop>
  );
};

export default DropZone;
