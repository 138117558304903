import React from 'react';
import classnames from 'classnames';
import Header from './Header';
import TimelineSwiper from './TimelineSwiper';
import Partners from 'components/Elements/Partners';
import * as styles from './business.module.scss';

const Business = ({ data }) => {
  return (
    <section>
      <div className="cr-wrap">
        <div className={classnames(styles.headerWrap)}>
          <Header data={data.header} />
        </div>

        <div className={classnames(styles.swiperWrap)}>
          <TimelineSwiper data={data.swiper} />
        </div>

        <div className={classnames(styles.partnersWrap)}>
          <Partners logos={data.logos} />
        </div>
      </div>
    </section>
  );
};

export default Business;
