import Home from '@/templates/pages/Home';
import GasStations from '@/templates/pages/GasStations';
import Features from '@/templates/pages/Features';
import ConvenienceRetail from '@/templates/pages/ConvenienceRetail';
import Careers from '@/templates/pages/Careers';
import Hospitality from '@/templates/pages/Hospitality';
import Leadership from '@/templates/pages/Leadership';
import AboutUs from '@/templates/pages/AboutUs';
import Blog from '@/templates/pages/Blog';
import ContactUs from '@/templates/pages/ContactUs';
import Retail from '@/templates/pages/Retail';
import Terms from '@/templates/pages/Terms';

const templatesList = {
  Homepage: Home,
  Cirrus_for_gas_stations: GasStations,
  Features,
  'Convenience Retail': ConvenienceRetail,
  Careers,
  Hospitality,
  Leadership,
  'About Us': AboutUs,
  Blog,
  Contact_us: ContactUs,
  Retail,
  Terms_Conditions: Terms,
};

export default templatesList;
