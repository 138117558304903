import React from 'react';
import MapPointsMobile from './Mobile';
import MapPointsDesktop from './Desktop';

import { MOBILE_KEY } from 'constants/devices.js';
import useDeviceState from 'hooks/useDeviceState';

const MapPoints = () => {
  const deviceState = useDeviceState();

  return deviceState !== MOBILE_KEY ? (
    <MapPointsDesktop />
  ) : (
    <MapPointsMobile />
  );
};

export default MapPoints;
