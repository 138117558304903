import React from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

const GraphicCard = ({ data, className }) => (
  <div className={classnames(styles.card, className)}>
    <div className={styles.card_inner}>
      {data.icon && (
        <img src={data.icon.sourceUrl} alt="" className={styles.card_icon} />
      )}
      <p className={classnames('cr-text-2', styles.card_title)}>{data.title}</p>
    </div>
  </div>
);

export default GraphicCard;
