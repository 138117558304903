// extracted by mini-css-extract-plugin
export var section = "research-module--section--2Gn1q";
export var inner = "research-module--inner--1KaMH";
export var jsAnimInit = "research-module--jsAnimInit--3zSoE";
export var subtitle = "research-module--subtitle--3bfrO";
export var title = "research-module--title--1HFRF";
export var description = "research-module--description--TFBJw";
export var pointA = "research-module--pointA--g5KXC";
export var pointB = "research-module--pointB--rI5im";
export var content = "research-module--content--2piMq";
export var mediaWrap = "research-module--mediaWrap--7cPnY";
export var media = "research-module--media--2V9XL";
export var animContainer = "research-module--animContainer--2haB3";
export var pointList = "research-module--pointList--USuk0";
export var pointTitle = "research-module--pointTitle--3pMaP";