import React from 'react';
import classnames from 'classnames';

import Button from 'components/UI/Button';
import Icon from 'components/UI/Icon';

import useGetCirrusoftModal from 'hooks/useGetCirrusoftModal';

const EcosystemLink = ({ data, className }) => {
  const { openGetCirrusModal } = useGetCirrusoftModal();

  return (
    <Button
      className={classnames(
        'cr-btn--icon cr-btn--large cr-btn--wide cr-btn--icon-red-bg',
        className
      )}
      onClick={openGetCirrusModal}
    >
      {data.text}
      <Icon name="corner-arrow-small-bold" />
    </Button>
  );
};

export default EcosystemLink;
