import React from 'react';
import classnames from 'classnames';

import WpImage from 'components/Elements/WPImage';
import { Link } from 'gatsby';

import useLocale from 'hooks/useLocale';
import Formating from 'assets/js/formating';

import * as styles from './styles.module.scss';

const HeadNews = ({ post }) => {
  const locale = useLocale();

  return (
    <Link to={post.uri} className={styles.news}>
      <div className={styles.news_img}>
        <WpImage
          src={post.blog.banner?.sourceUrl}
          alt={post.title}
          className={styles.news_img_inner}
        />
      </div>
      <div className={styles.news_inner}>
        <div className={classnames('cr-sub', styles.news_date)}>
          <span>{Formating.date(post.dateGmt, locale.code)}</span>
        </div>
        <div>
          <p
            className={classnames(
              'cr-subheader-4 cr-text-3',
              styles.newstext_suptitle
            )}
          >
            {post.categories.nodes.map(({ name }) => name).join(' ')}
          </p>
          <p className={classnames('cr-h2 cr-white', styles.newstext_title)}>
            {post.title}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default HeadNews;
