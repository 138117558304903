import React from 'react';
import SolutionMobile from './components/SolutionMobile';
import SolutionDesktop from './components/SolutionDesktop';

import { DESKTOP_KEY } from 'constants/devices.js';
import useDeviceState from 'hooks/useDeviceState';

const Solution = ({ data }) => {
  const deviceState = useDeviceState();

  return deviceState !== DESKTOP_KEY ? (
    <SolutionMobile data={data} />
  ) : (
    <SolutionDesktop data={data} />
  );
};

export default Solution;
