import React, { useRef, useState } from 'react';
import classnames from 'classnames';

import * as styles from './DropdownForm.module.scss';
import Icon from 'components/UI/Icon';
import ClickOutsideWrapper from './components/ClickOutsideWrapper';

const Dropdown = ({
  list,
  placeholder = 'Select...',
  error = '',
  onSelect,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);

  const listRef = useRef(null);

  const selectItem = (e, data) => {
    setOpen(!open);
    setValue(data.option);

    if (onSelect) onSelect(data);
  };

  const handleClikOutside = () => {
    setOpen(false);
  };

  return (
    <div className={styles.wrap}>
      <div
        className={classnames(styles.btn, {
          [styles.open]: open,
          [styles.error]: error,
          [styles.selected]: value && !error,
        })}
        onClick={() => setOpen(!open)}
      >
        <span
          className={classnames('cr-reg', {
            [styles.disabled]: !value,
          })}
        >
          {value || placeholder}
        </span>
        <Icon name="arrow-down" />
      </div>
      {error && (
        <span className={classnames('cr-sub', styles.error_text)}>{error}</span>
      )}

      <ClickOutsideWrapper onClickOutside={handleClikOutside}>
        <ul
          className={classnames(styles.list, 'cr-reg', {
            [styles.open]: open,
          })}
          ref={listRef}
        >
          {list.map((item, i) => {
            return (
              <li key={i} onClick={(e) => selectItem(e, item)}>
                {item.option}
              </li>
            );
          })}

          {!list.length && <li>List is empty</li>}
        </ul>
      </ClickOutsideWrapper>
    </div>
  );
};

export default Dropdown;
