import React, { useMemo } from 'react';

import { getImage, withArtDirection } from 'gatsby-plugin-image';

import useStaticImage from 'hooks/graphql/useStaticImage';
import Transactions from '@/sections/common/ServiceTransactions';

import * as styles from './styles.module.scss';

const ConvRetailTransactions = ({ data }) => {
  const getStaticImage = useStaticImage();

  const images = useMemo(
    () =>
      withArtDirection(
        getImage(
          getStaticImage('retail/trasactions/bg-desktop.jpg').childImageSharp
        ),
        [
          {
            media: '(min-width: 577px) and (max-width: 1200px)',
            image: getImage(
              getStaticImage('retail/trasactions/bg-tablet.jpg').childImageSharp
            ),
          },
          {
            media: '(max-width: 576px)',
            image: getImage(
              getStaticImage('retail/trasactions/bg-mobile.jpg').childImageSharp
            ),
          },
        ]
      ),
    [getStaticImage]
  );

  return (
    <Transactions data={data} images={images} bgClassName={styles.trans_bg} />
  );
};

export default ConvRetailTransactions;
