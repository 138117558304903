import React, { useMemo } from 'react';

import { getImage, withArtDirection } from 'gatsby-plugin-image';

import ServiceHero from 'sections/common/ServiceHero';
import useStaticImage from 'hooks/graphql/useStaticImage';

import * as styles from './styles.module.scss';

const Hero = ({ data }) => {
  const getStaticImage = useStaticImage();

  const posters = useMemo(
    () =>
      withArtDirection(
        getImage(
          getStaticImage('hospitality/hero/bg-desktop.png').childImageSharp
        ),
        [
          {
            media: '(min-width: 577px) and (max-width: 1200px)',
            image: getImage(
              getStaticImage('hospitality/hero/bg-tablet.png').childImageSharp
            ),
          },
          {
            media: '(max-width: 576px)',
            image: getImage(
              getStaticImage('hospitality/hero/bg-mobile.png').childImageSharp
            ),
          },
        ]
      ),
    [getStaticImage]
  );

  return (
    <ServiceHero bgClassName={styles.hero_bg} posters={posters} data={data} />
  );
};

export default Hero;
