import React from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

const PartnerLink = ({ isActive, img, onClick }) => (
  <button
    type="button"
    className={classnames(styles.link, { [styles.active]: isActive })}
    onClick={onClick}
  >
    <img src={img} alt="" />
  </button>
);

export default PartnerLink;
