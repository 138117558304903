import React from 'react';
import Mobile from './components/Mobile';
import Desktop from './components/Desktop';

import { DESKTOP_KEY } from 'constants/devices.js';
import useDeviceState from 'hooks/useDeviceState';

const Opportunities = ({ data }) => {
  const deviceState = useDeviceState();

  return deviceState !== DESKTOP_KEY ? (
    <Mobile data={data} />
  ) : (
    <Desktop data={data} />
  );
};

export default Opportunities;
