import React from 'react';
import classnames from 'classnames';
import * as styles from './styles.module.scss';

const EmptyState = () => (
  <div className={styles.empty}>
    <p className={classnames('cr-h2')}>List is empty</p>
  </div>
);

export default EmptyState;
