import React from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';
import ImgSwiper from './ImgSwiper';
import Icon from 'components/UI/Icon';
import * as styles from './leadership.module.scss';

const Leadership = ({ data }) => {
  return (
    <section className={classnames(styles.section)} id="Management">
      <div className="cr-wrap">
        <div className={classnames(styles.hero)}>
          <p className={classnames('cr-subheader cr-subtitle-sec  cr-text-3')}>
            {data.subtitle}
          </p>
          <h2
            className={classnames(
              'cr-h2 cr-subtitle-sec cr-underline-gradient cr-underline-gradient--center'
            )}
          >
            {data.title}
          </h2>
          <p className={classnames('cr-lead')}>{data.description}</p>
        </div>
      </div>

      <ImgSwiper data={data.swiper} />

      <div className="cr-wrap">
        <div className={classnames(styles.controls)}>
          <Link
            to={data.button.url}
            className="cr-btn cr-btn--icon cr-btn--large cr-btn--wide cr-btn--icon-red-bg"
          >
            {data.button.text ? (
              data.button.text
            ) : (
              <span>Meet our leaders</span>
            )}
            <Icon name="corner-arrow-small-bold" />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Leadership;
