import React from 'react';
import Layout from 'sections/Layout';

import Hero from 'sections/pages/Leadership/Hero';
import Team from 'sections/pages/Leadership/Team';
import Join from 'sections/common/Join';

import 'sections/pages/Leadership/styles.scss';

const Leadership = ({ page }) => {
  const {
    template: { leadership_fields: leadershipPage },
    seo,
  } = page;

  const HERO_DATA = {
    title: leadershipPage.title,
    titleHighlighted: leadershipPage.heroTitleHighlighted,
    subtitle: leadershipPage.heroSubtitle,
    desc: leadershipPage.heroDescription,
  };

  const TEAM_DATA = {
    list: (leadershipPage.leadersList || []).map((leader) => ({
      name: leader.leaderName,
      position: leader.leaderPosition,
      photo: leader.leaderPhoto?.sourceUrl,
      socLinkUrl: leader.socialUrl?.url || '#',
      description: (leader.leaderDescription || []).map(
        ({ descriptionText, descriptionTitle }) => ({
          title: descriptionTitle,
          content: descriptionText,
        })
      ),
    })),
    becomeFriend: {
      title: leadershipPage.connectTitle,
      description: leadershipPage.connectDescription,
      socLinkUrl: leadershipPage.connecrSocialUrl || '#',
    },
  };

  return (
    <Layout
      currentPageClass={'cr-leadership-page'}
      headerClass={'cr-leadership-header'}
      mainClass={'cr-leadership-main'}
      footerClass={'cr-leadership-footer'}
      seo={seo}
    >
      {(commonData) => (
        <>
          <section className="cr-leadership__hero">
            <Hero data={HERO_DATA} />
          </section>

          <section className="cr-wrap cr-leadership__team">
            <Team data={TEAM_DATA} />
          </section>

          <section className="cr-leadership__join">
            <Join />
          </section>
        </>
      )}
    </Layout>
  );
};

export default Leadership;
