import * as Yup from 'yup';
import getRules from 'assets/js/validRules';

const rules = (locale) => {
  const RULES = getRules(locale);

  return Yup.object({
    area: RULES.required,
    post: RULES.required,
    file_cv: RULES.required,
  });
};

export default rules;
