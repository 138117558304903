const { commonPageData } = require('./pages/commonData.js');
const { homePage } = require('./pages/home.js');
const { gasStationsPage } = require('./pages/gasStationsPage.js');
const { featuresPage } = require('./pages/featuresPage.js');
const { convRetailPage } = require('./pages/convRetailPage.js');
const { hospitalityPage } = require('./pages/hospitalityPage.js');
const { leadershipPage } = require('./pages/leadershipPage.js');
const { contactUsPage } = require('./pages/contactUsPage.js');
const { retailPage } = require('./pages/retailPage.js');
const { careersPage } = require('./pages/careersPage.js');
const { blogPage } = require('./pages/blogPage.js');
const { aboutUsPage } = require('./pages/aboutUsPage.js');
const { termsPage } = require('./pages/termsPage.js');
const { privacyPage } = require('./pages/privacyPage.js');

const page = (WpTag = '') => `
  ${commonPageData(WpTag)}
  template {
    ... on ${WpTag}Template_Homepage {
      homepage {
        ${homePage(WpTag)}
      }
    }

    ... on ${WpTag}Template_Cirrus_for_gas_stations {
      gas_stations_fields {
        ${gasStationsPage(WpTag)}
      }
    }

    ... on ${WpTag}Template_Features {
      features_fields {
        ${featuresPage(WpTag)}
      }
    }

    ... on ${WpTag}Template_ConvenienceRetail {
      convenince_retails_fields {
        ${convRetailPage(WpTag)}
      }
    }

    ... on ${WpTag}Template_Hospitality {
      hospitality_fields {
        ${hospitalityPage(WpTag)}
      }
    }

    ...on ${WpTag}Template_Careers {
      careers {
        ${careersPage(WpTag)}
      }
    }

    ... on ${WpTag}Template_Leadership {
      leadership_fields {
        ${leadershipPage(WpTag)}
      }
    }

    ...on ${WpTag}Template_AboutUs {
      about_us_fields {
        ${aboutUsPage(WpTag)}
      }
    }

    ... on ${WpTag}Template_Contact_us {
      contactus_fields {
        ${contactUsPage(WpTag)}
      }
    }
    
    ... on ${WpTag}Template_Retail {
      convenince_retails_fields {
        ${retailPage(WpTag)}
      }
    }

    ... on ${WpTag}Template_Blog {
      blog {
        ${blogPage(WpTag)}
      }
    }
    
    ...on ${WpTag}Template_Terms_Conditions {
      termsConditions {
        ${termsPage(WpTag)}
      }
    }
    
    ...on ${WpTag}Template_Terms_Conditions {
      termsConditions {
        ${privacyPage(WpTag)}
      }
    }
  }
`;

module.exports.page = page;
