import React, { useRef } from 'react';
import classnames from 'classnames';

import { Link } from 'gatsby';

import Icon from 'components/UI/Icon';
import ScaleOnScroll from '@/helpers/scaleOnScroll';
import WpImage from 'components/Elements/WPImage';

import useIntersection from 'hooks/useIntersection';

import * as styles from './careers.module.scss';

const Careers = ({ data }) => {
  const scrollWrap = useRef();
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.5,
  });

  return (
    <section
      className={classnames(styles.section)}
      ref={scrollWrapper}
      id="Work-with-us"
    >
      <div className="cr-wrap">
        <div
          className={classnames(styles.inner, {
            [styles.jsInitAnim]: inViewSec,
          })}
          ref={inViewRefSec}
        >
          <div className={classnames(styles.imgWrap)}>
            <ScaleOnScroll scrollWrapper={scrollWrap}>
              {(scale) => (
                <div
                  ref={scrollWrap}
                  style={{
                    willChange: 'transform',
                    transform: `scale3d(${scale.x}, ${scale.y}, ${scale.z})`,
                  }}
                >
                  <WpImage
                    src={data.image.sourceUrl}
                    alt={data.image.altText}
                  />
                </div>
              )}
            </ScaleOnScroll>
          </div>
          <div className={classnames(styles.content)}>
            <p className={classnames('cr-subheader cr-subtitle-sec cr-text-3')}>
              {data.subtitle}
            </p>
            <h2
              className={classnames(
                styles.title,
                'cr-h2 cr-subtitle-sec cr-underline-gradient'
              )}
            >
              {data.title}
            </h2>
            <p className={classnames(styles.description, 'cr-lead')}>
              {data.description}
            </p>

            <Link
              to={data.button.url}
              className="cr-btn cr-btn--icon cr-btn--large cr-btn--wide cr-btn--icon-red-bg"
            >
              {data.button.text ? (
                data.button.text
              ) : (
                <span>Discover career oportunities</span>
              )}

              <Icon name="corner-arrow-small-bold" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Careers;
