import React from 'react';
import classnames from 'classnames';
import BenefitItem from './BenefitItem';
import useIntersection from 'hooks/useIntersection';
import * as styles from './benefits.module.scss';

const Benefits = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.4,
  });

  return (
    <section className={classnames(styles.section)} ref={scrollWrapper}>
      <div className="cr-wrap">
        <ul
          className={classnames(styles.list, inViewSec && styles.jsAnimInit)}
          ref={inViewRefSec}
        >
          {data.length &&
            data.map((benefit, i) => {
              return (
                <li key={i}>
                  <BenefitItem data={benefit} />
                </li>
              );
            })}
        </ul>
      </div>
    </section>
  );
};

export default Benefits;
