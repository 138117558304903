import React, { useMemo } from 'react';
import classnames from 'classnames';

import { getImage, withArtDirection, GatsbyImage } from 'gatsby-plugin-image';

import useIntersection from 'hooks/useIntersection';
import useStaticImage from 'hooks/graphql/useStaticImage';

import * as styles from './styles.module.scss';
import 'styles/common/_hero.scss';

const Hero = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.6,
  });
  const getStaticImage = useStaticImage();

  const posters = useMemo(
    () =>
      withArtDirection(
        getImage(
          getStaticImage('leadership/hero/bg-desktop.png').childImageSharp
        ),
        [
          {
            media: '(min-width: 577px) and (max-width: 1200px)',
            image: getImage(
              getStaticImage('leadership/hero/bg-tablet.png').childImageSharp
            ),
          },
          {
            media: '(max-width: 576px)',
            image: getImage(
              getStaticImage('leadership/hero/bg-mobile.png').childImageSharp
            ),
          },
        ]
      ),
    [getStaticImage]
  );

  return (
    <div className={styles.hero} ref={scrollWrapper}>
      <div className={styles.hero_bg}>
        <GatsbyImage
          image={posters}
          alt={data.title || data.titleHighlighted}
        />
      </div>

      <div
        className={classnames('cr-wrap', styles.hero_inner, {
          [styles.jsAnimInit]: inViewSec,
        })}
        ref={inViewRefSec}
      >
        <div className={classnames('cr-common-hero-subtitle', styles.hero_top)}>
          <p className={classnames('cr-subheader cr-text-3')}>
            {data.subtitle}
          </p>
        </div>

        <div className={styles.hero_center}>
          <h1 className={classnames('cr-h1', styles.hero_title)}>
            <span className={classnames('cr-white')}>{data.title}</span>
            <span className={classnames('cr-gradient')}>
              {data.titleHighlighted}
            </span>
          </h1>

          <p className={classnames('cr-white cr-lead', styles.hero_desc)}>
            {data.desc}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
