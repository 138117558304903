import React from 'react';
import { getImage } from 'gatsby-plugin-image';

import Layout from '@/sections/Layout';
import Hero from '@/sections/pages/Retail/Hero';
import Solutions from '@/sections/common/Solutions';
import InfoGraphics from '@/sections/common/ServiceInfoGraphics';
import Transactions from '@/sections/pages/Retail/Transactions';
import Crossplatform from '@/sections/common/Crossplatform';
import Benefits from '@/sections/common/Benefits';
import Registration from '@/sections/common/Registration';
import Blog from 'components/Blog';

import useStaticImage from 'hooks/graphql/useStaticImage';

import {
  parseServiceBenefitsTable,
  parseInfoGraphicList,
  parseInfoGraphicBtn,
} from './parseFuncs';

import '@/sections/pages/Retail/styles.scss';

const Retail = ({ page }) => {
  const getStaticImage = useStaticImage();

  const {
    template: { convenince_retails_fields: retailPage },
    seo,
    blogCategories,
  } = page;

  const HERO_DATA = {
    title: retailPage.heroTitle,
    titleHighlighted: retailPage.heroTitleHighlighted,
    subtitle: retailPage.heroSubtitle,
    list: (retailPage.heroList || []).map(({ heroListItem }) => heroListItem),
  };

  const SOLUTION_DATA = {
    title: retailPage.solutionsTitle,
    subtitle: retailPage.solutionsSubtitle,
    list: (retailPage.solutionsCardlist || []).map(
      ({ itemText, itemIcon }) => ({
        title: itemText,
        iconSrc: itemIcon?.sourceUrl,
      })
    ),
  };

  const INFO_GRAPHICS_DATA_1 = {
    title: retailPage.infograph1Title,
    suptitle: retailPage.infograph1Subtitle,
    mediaSrc: getImage(
      getStaticImage('retail/info-graphics/img1.png').childImageSharp
    ),
    list: parseInfoGraphicList(retailPage.infograph1TextItems),
    btn1: parseInfoGraphicBtn(retailPage.infograph1Button1),
    btn2: parseInfoGraphicBtn(retailPage.infograph1Button2),
  };

  const INFO_GRAPHICS_DATA_2 = {
    title: retailPage.infograph2Title,
    suptitle: retailPage.infograph2Subtitle,
    mediaSrc: getImage(
      getStaticImage('retail/info-graphics/img2.png').childImageSharp
    ),
    list: parseInfoGraphicList(retailPage.infograph2TextItems),
    btn1: parseInfoGraphicBtn(retailPage.infograph2Button1),
    btn2: parseInfoGraphicBtn(retailPage.infograph2Button2),
    isReversed: true,
  };

  const INFO_GRAPHICS_DATA_3 = {
    title: retailPage.infograph3Title,
    suptitle: retailPage.infograph3Subtitle,
    mediaSrc: getImage(
      getStaticImage('retail/info-graphics/img3.png').childImageSharp
    ),
    list: parseInfoGraphicList(retailPage.infograph3TextItems),
    btn1: parseInfoGraphicBtn(retailPage.infograph3Button1),
    btn2: parseInfoGraphicBtn(retailPage.infograph3Button2),
  };

  const TRANSACTION_DATA = {
    card: {
      title: retailPage.transTitle,
      desc: retailPage.transLead,
    },
  };

  const CROSSPLATFORM_DATA = {
    title: retailPage.crossplatTitle,
    desc: retailPage.crossplatLead,
  };

  const BENEFITS_DATA = {
    title: retailPage.benefitsTitle,
    benefitsTh: retailPage.benefitsTableTh,
    benefits: parseServiceBenefitsTable(retailPage.benefitsTable),
  };

  return (
    <Layout
      currentPageClass={'cr-retail-page'}
      headerClass={'cr-retail-header'}
      mainClass={'cr-retail-main'}
      footerClass={'cr-retail-footer'}
      seo={seo}
    >
      {(commonData) => (
        <>
          <section className="cr-retail__hero">
            <Hero data={HERO_DATA} />
          </section>

          <section className="cr-wrap cr-retail__solutions">
            <Solutions data={SOLUTION_DATA} />
          </section>

          <section className="cr-wrap cr-conv-retail__info-grap">
            <InfoGraphics data={INFO_GRAPHICS_DATA_1} />
          </section>

          <section className="cr-wrap cr-conv-retail__info-grap">
            <InfoGraphics data={INFO_GRAPHICS_DATA_2} />
          </section>

          <section className="cr-wrap cr-conv-retail__info-grap">
            <InfoGraphics data={INFO_GRAPHICS_DATA_3} />
          </section>

          <section className="cr-retail__trans">
            <Transactions data={TRANSACTION_DATA} />
          </section>

          <section className="cr-wrap cr-retail__cross-plat">
            <Crossplatform data={CROSSPLATFORM_DATA} />
          </section>

          <section className="cr-wrap cr-retail__benefits">
            <Benefits data={BENEFITS_DATA} />
          </section>

          <section className="cr-wrap cr-retail__registr">
            <Registration />
          </section>

          <Blog data={blogCategories} noMargin={true} />
        </>
      )}
    </Layout>
  );
};

export default Retail;
