import React from 'react';
import classnames from 'classnames';
import useIntersection from 'hooks/useIntersection';
import * as styles from './logos.module.scss';

const Partners = ({ logos }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection();

  return (
    <div className={classnames(styles.logos)} ref={scrollWrapper}>
      <ul
        className={classnames(styles.list, { [styles.jsAnimInit]: inViewSec })}
        ref={inViewRefSec}
      >
        {logos &&
          logos.map((logo) => (
            <li key={logo.logoSrc}>
              <div className={classnames(styles.item)}>
                <img src={logo.logoSrc} alt="" />
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Partners;
