import React from 'react';
import * as styles from './styles.module.scss';

import Transactions from 'sections/common/ServiceTransactions';

const ConvRetailTransactions = ({ data }) => (
  <Transactions data={data} bgClassName={styles.trans_bg} />
);

export default ConvRetailTransactions;
