import { useEffect, useState } from 'react';

const mix = (x, y, a) => x * (1 - a) + y * a;

const isElementOutViewport = (el) => {
  if (!el) return false;

  const rect = el.getBoundingClientRect();

  return rect.bottom < 0 || rect.top > window.innerHeight;
};

const ScaleOnScroll = ({
  children,
  scrollWrapper = false,
  percentIteration = '0.4',
}) => {
  const [scale, setScale] = useState(0);

  useEffect(() => {
    const getPercent = (el) => {
      if (!el) return 0;

      const top = el.getBoundingClientRect().top;
      const dist = el.offsetHeight + window.innerHeight;
      const offset = window.innerHeight - top;
      const percent = (offset / dist) * percentIteration;

      return percent;
    };

    const handleScroll = () => {
      window.requestAnimationFrame(() => {
        if (!scrollWrapper || isElementOutViewport(scrollWrapper.current))
          return;

        const scale = mix(1, 1.3, getPercent(scrollWrapper.current));

        setScale({
          x: scale,
          y: scale,
          z: 1,
        });
      });
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll, false);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return children(scale);
};

export default ScaleOnScroll;
