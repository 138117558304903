const aboutUsPage = (WpTag) => `
  heroSubtitle
  heroTitle
  heroTitleHighlighted
  heroDescription
  glanceSubtitle
  glanceTitle
  glanceImage {
    id
  }
  glanceStatistics {
    title
    description
  }
  bannerDescription
  bannerImage {
    altText
    srcSet
    sourceUrl
    mediaItemUrl
  }
  benefits {
    icon {
      altText
      srcSet
      sourceUrl
      mediaItemUrl
    }
    title
    description
  }
  businessTitle
  businessDescription
  businessSwiper {
    year
    description
    image {
      altText
      srcSet
      sourceUrl
      mediaItemUrl
    }
    logo {
      altText
      srcSet
      sourceUrl
      mediaItemUrl
    }
  }
  researchSubtitle
  researchTitle
  researchDescription
  researchMapPoinATitle
  researchMapPoinASubtitle
  researchMapPoinAList {
    item
  }
  researchMapPoinBTitle
  researchMapPoinBSubtitle
  researchMapPoinBList {
    item
  }
  industryTitle
  industryTitleHighlighted
  industryDescription
  industryLogosList {
    logoImage {
      altText
      sourceUrl
      srcSet
      mediaItemUrl
    }
  }
  industryImage {
    altText
    srcSet
    sourceUrl
    mediaItemUrl
  }
  leadershipSubtitle
  leadershipTitle
  leadershipDescription
  leadershipSwiper {
    leaderName
    slideImage {
      altText
      srcSet
      sourceUrl
      mediaItemUrl
    }
  }
  leadershipButtonText
  leadershipButtonUrl {
    url
  }
  careersSubtitle
  careersTitle
  careersDescription
  careersImage {
    altText
    mediaItemUrl
    srcSet
    sourceUrl
  }
  careersButtonText
  careersButtonUrl {
    url
  }
  blogSubtitle
  blogTitle
  partners {
    logo {
      altText
      srcSet
      sourceUrl
      mediaItemUrl
    }
  }
`;

module.exports.aboutUsPage = aboutUsPage;
