import React from 'react';
import classnames from 'classnames';
import useIntersection from 'hooks/useIntersection';
import * as styles from './header.module.scss';

const Header = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.8,
  });

  return (
    <div ref={scrollWrapper}>
      <div
        className={classnames(styles.hero, inViewSec && styles.jsAnimInit)}
        ref={inViewRefSec}
      >
        <h2
          className={classnames(
            styles.title,
            'cr-h2 cr-subtitle-sec cr-underline-gradient'
          )}
        >
          {data.title && data.title}
        </h2>
        <p className={classnames(styles.description, 'cr-lead')}>
          {data.description && data.description}
        </p>
      </div>
    </div>
  );
};

export default Header;
